import firebase from "./../Firebase";
import {environment} from "../../config/env"
const fn_getDistance = firebase.functions().httpsCallable("getDistance");

interface LatLng {
  lat: string;
  lng: string;
}

interface Directions {
  status: "NOT_FOUND" | "OK";
  geocoded_waypoints: Array<{
    geocoder_status: string;
    place_id: string;
    types: Array<string>;
  }>;
  routes: Array<{
    bounds: { northeast: LatLng; southwest: LatLng };
    copyrights: string;
    legs: Array<{
      distance: {
        text: string;
        value: number;
      };
      duration: {
        text: string;
        value: number;
      };
      end_address: string;
      end_location: LatLng;
      start_address: string;
      start_location: LatLng;
      steps: Array<{
        distance: {
          text: string;
          value: number;
        };
        duration: {
          text: string;
          value: number;
        };
        end_location: LatLng;
        html_instructions: string;
        polyline: {
          points: string;
        };
        start_location: LatLng;
        travel_mode: string;
      }>;
    }>;
  }>;
}

export const getDistance = async (
  origin: string,
  waypoints: Array<string>,
  mode = "driving"
): Promise<number> => {
  const destination = waypoints[0];
  const _waypoints = waypoints.slice(1);
  return fn_getDistance({
    origin,
    destination,
    waypoints: _waypoints?.join("|"),
    mode,
  }).then((res) => {
    const directions: Directions = res.data;

    // console.log(directions);
    if (directions.status === "OK") {
      let distance = 0;

      for (const route of directions?.routes) {
        for (const leg of route.legs) {
          distance += leg.distance.value;
        }
      }
      return distance;
    }
    throw new Error(
      "Something went wrong! Please check if your addresses are correct."
    );
  });
};

export const deActivateUser = async (
  collectionType: "driver" | "producer",
  uid: string
): Promise<void> => {
  fetch(`https://us-central1-${environment.FIREBASE.projectId}.cloudfunctions.net/deleteUser`, {
    method: "POST",
    headers: new Headers({
      "Authorization": "Bearer " + await firebase.auth().currentUser?.getIdToken() || "",
      "Content-Type": "application/json"
    }),
    body: JSON.stringify({ collectionType, uid })
  })
};

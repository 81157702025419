export const gridSpacing = 3;
export const drawerWidth = 260;

// export enum PROVINCES {
//   alberta = "Alberta",
//   british_colombia = "British Columbia",
//   manitoba = "Manitoba",
//   new_brunswick = "New Brunswick",
//   newfoundland = "Newfoundland and Labrador",
//   northwest = "Northwest Territories",
//   nova_scotia = "Nova Scotia",
//   nunavut = "Nunavut",
//   ontario = "Ontario",
//   price_edward = "Prince Edward Island",
//   quebec = "Quebec",
//   saskatchewan = "Saskatchewan",
//   yukon = "Yukon",
// }

export const PROVINCES = {
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  Nunavut: "NU",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "QC",
  Saskatchewan: "SK",
  Yukon: "YT",
};

// stripe billing_reasons list
/*** billing_reason
string
Indicates the reason why the invoice was created. 
subscription_cycle indicates an invoice created by a subscription advancing into a new period. 
subscription_create indicates an invoice created due to creating a subscription.
 subscription_update indicates an invoice created due to updating a subscription. 
 subscription is set for all old invoices to indicate either a change to a subscription or a period advancement. 
 manual is set for all invoices unrelated to a subscription (for example: created via the invoice editor). 
 The upcoming value is reserved for simulated invoices per the upcoming invoice endpoint. 
 subscription_threshold indicates an invoice created due to a billing threshold being reached. ***/

export const BillingReasonsList = [
  {
    value: "subscription_cycle",
    label: "Invoice created by a subscription advancing into a new period",
  },
  {
    value: "subscription_create",
    label: "Invoice created due to creating a subscription",
  },
  {
    value: "subscription_update",
    label: "Invoice created due to updating a subscription",
  },
  {
    value: "subscription_threshold",
    label: "Invoice created due to a billing threshold being reached",
  },
];

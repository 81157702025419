import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import { environment } from "../config/env";

if (!firebase.apps.length) {
  firebase.initializeApp(environment.FIREBASE);
}

export const db = firebase.database();
export const fs = firebase.firestore();
export const auth = firebase.auth();
export const store = firebase.storage();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export default firebase;

export enum FirebaseCollections {
  driver = "driver",
  admin = "admin",
  producer = "producer",
  order = "order",
  notification = "notification",
  bids = "bids",
  orderStatusHistory = "history",
  securityDepositHistory = "security_deposit_history",
  reviews = "reviews",
  paymentHistory = "payment_history",
}
export const driverCollection = fs.collection(FirebaseCollections.driver);
export const producerCollection = fs.collection(FirebaseCollections.producer);
export const orderCollection = fs.collection(FirebaseCollections.order);
export const adminCollection = fs.collection(FirebaseCollections.admin);

import { Image } from "../../types/model";
import { store } from "../Firebase";
export const storeImage = async (
  imagePath: string,
  image: Image
): Promise<void> => {
  const imageRef = await store.ref().child(imagePath);
  await imageRef.putString(image.data, "data_url");
};

export const getImageUrl = async (imagePath: string): Promise<string> => {
  return store
    .ref(imagePath)
    .getDownloadURL()
    .then((url) => {
      return url;
    });
};

/* eslint-disable react/prop-types */

import firebase from "firebase/app";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getAdminById } from "../Firebase/dao/adminDao";
import { auth } from "../Firebase/Firebase";
import { Admin } from "../types/model";

export type IAuthContext = {
  isAuthenticated: boolean;
  user: Admin | null;
  login: (email: string, password: string, _redirect?: string) => Promise<void>;
  loading: boolean;
  logout: () => void;
  redirect?: string;
};

export const AuthContext = createContext({} as IAuthContext);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Admin | null>(null);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {
    setLoading(true);
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const _user = await getAdminById(user.uid);
          setUser(_user);
          setLoading(false);
        } else {
          setUser(null);
          setLoading(false);
        }
      } catch (e) {
        auth.signOut();
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const login = async (email: string, password: string, _redirect?: string) => {
    _redirect && setRedirect(_redirect);
    setLoading(true);
    await auth
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL) //SESSION || NONE
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password);
      })
      .then(async ({ user }) => {
        if (user) {
          const _user = await getAdminById(user.uid);
          setUser(_user);
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        auth.signOut();
        setUser(null);
        throw e;
      });
  };

  const logout = async () => {
    setLoading(true);
    await auth.signOut().then(() => {
      setUser(null);
      setLoading(false);
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        login,
        loading,
        logout,
        redirect,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = (): IAuthContext => useContext(AuthContext);

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner as ReactSpinner,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { validateEmail } from "../../utils/util";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/AdminContext";
import { sendPasswordResetEmail } from "../../Firebase/dao/adminDao";
import Background from "../../assets/img/pk-pro-cover.jpg";

export default function ForgotPassword(props: any) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [Password, setPassword] = useState("");
  const [alert, setAlert] = useState<React.ReactElement | null>(null);
  const [redirect, setRedirect] = useState(null);
  const [marginTop, setMarginTop] = useState("calc(50vh - 230px)");
  const history = useHistory();
  const auth = useAuth();
  const loading = auth.loading;

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push("/admin/all-deliveries");
    }
  }, [auth]);

  // function to handle forgot password
  const handleSubmit = () => {
    if (email === null || email === "") {
      setAlert(
        <ReactBSAlert
          error
          // style={{ display: "block", marginTop: "100px" }}
          title="Must have an email."
          onCancel={console.log}
          confirmBtnBsStyle="info"
          onConfirm={() => setAlert(null)}
        />
      );
      setError("* ");
      return;
    } else {
      if (validateEmail(email) === false) {
        setAlert(
          <ReactBSAlert
            error
            // style={{ display: "block", marginTop: "100px" }}
            title="Must have a valid email."
            onCancel={console.log}
            confirmBtnBsStyle="info"
            onConfirm={() => setAlert(null)}
          />
        );
        return;
      } else {
        setError("");
        sendToFirebase();
      }
    }
  };

  // function to send firebase
  const sendToFirebase = async () => {
    const tempEmail = email.toLowerCase();
    try {
      await sendPasswordResetEmail(tempEmail);
      setAlert(
        <ReactBSAlert
          success
          // style={{ display: "block", marginTop: "100px" }}
          title="Reset your password"
          onCancel={console.log}
          confirmBtnBsStyle="info"
          onConfirm={() => setAlert(null)}
        >
          A password reset link has been sent to your email. Please use the link
          to create a new password.
        </ReactBSAlert>
      );
    } catch (error) {
      console.log(error);
      setAlert(
        <ReactBSAlert
          error
          // style={{ display: "block", marginTop: "100px" }}
          title="Login Error"
          onCancel={console.log}
          confirmBtnBsStyle="info"
          onConfirm={() => setAlert(null)}
        >
          {error.message}
        </ReactBSAlert>
      );
    }
  };

  return (
    <div className="wrapper wrapper-full-page">
      <div className="full-page section-image">
        <div className="login-page">
          {alert}

          <div
            className="full-page-background"
            style={{
              backgroundColor: "rgb(221,221,221)",
              backgroundImage: `url(${Background})`,
              zIndex: 2,
            }}
          />

          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="3"></Col>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form
                  style={{
                    marginTop: marginTop,
                    fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                  }}
                  action=""
                  className="form"
                  method=""
                >
                  <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                    {loading && (
                      <ReactSpinner
                        className="text-center"
                        type="border"
                        color="secondary"
                        size="6"
                      />
                    )}
                  </div>
                  <Card className="card-login">
                    <CardHeader style={{ height: "80px", paddingTop: "10px" }}>
                      <CardHeader>
                        <h3 className="header text-center">Forgot Password</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError("");
                          }}
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="btn-round mb-3"
                        // color="warning"
                        // href="#pablo"
                        onClick={handleSubmit}
                        // style={{backgroundColor : '#004A8C'}}
                        style={{
                          backgroundColor: "rgb(30,30,30)",
                          width: "100%",
                        }}
                        disabled={!!loading}
                      >
                        {loading ? (
                          <ReactSpinner size={10} />
                        ) : (
                          "Send Reset Password Link"
                        )}
                      </Button>
                      <Button
                        block
                        className="btn-round mb-3"
                        // color="warning"
                        // href="#pablo"
                        onClick={() => history.push("/login")}
                        // style={{backgroundColor : '#004A8C'}}
                        style={{
                          backgroundColor: "rgb(30,30,30)",
                          width: "100%",
                        }}
                        disabled={!!loading}
                      >
                        {loading ? <ReactSpinner size={10} /> : "Back"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="3"></Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Formik } from "formik";
// reactstrap components
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Driver } from "../../../../types/model";
import { useStyles } from "../../../../styles";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateDriver } from "../../../../Firebase/dao/driverDao";

interface Props {
  data: Driver;
}

function Profile({ data }: Props) {
  const classes = useStyles();
  const [alert, setAlert] = useState<any>(null);

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div className="content">
      <Formik
        initialValues={{
          firstName: data?.firstName,
          lastName: data?.lastName,
          phone: data?.phone,
          email: data?.email,
          securityDeposit: data?.securityDeposit ? data?.securityDeposit/100 : 0,
          submit: "",
        }}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            let updates: Partial<Driver> = {
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              securityDeposit: values.securityDeposit * 100,// convert to cents 
            };
            data.id && (await updateDriver(data.id, updates));
            setAlert(
              <SweetAlert
                success
                title="Driver profile updated successfully"
                onConfirm={hideAlert}
              />
            );
          } catch (error) {
            setAlert(
              <SweetAlert
                error
                title={error.response.message || error.message}
                onConfirm={hideAlert}
              />
            );
          }
        }}
        validateOnBlur
        validateOnChange
        validate={async (values) => {
          let errors = {};

          if (!values.firstName) {
            errors = {
              ...errors,
              firstName: "Please enter first Name.",
            };
          }
          if (!values.lastName) {
            errors = { ...errors, lastName: "Please enter last Name." };
          }
          if (!values.phone) {
            errors = { ...errors, phone: "Please enter phone number." };
          } else if (values.phone.length !== 10) {
            errors = { ...errors, phone: "Please enter valid phone number." };
          }

          if (!values.securityDeposit) {
            errors = { ...errors, securityDeposit: "Please enter valid number." };
          } 
          return errors;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    value={values.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    type="text"
                    name="firstName"
                  />
                  <FormText color="danger" tag="span">
                    {errors.firstName}
                  </FormText>
                </FormGroup>
              </Col>

              <Col className="pl-1" md="6">
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    value={values.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                  <FormText color="danger" tag="span">
                    {errors.lastName}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    type="text"
                    name="phone"
                  />
                  <FormText color="danger" tag="span">
                    {errors.phone}
                  </FormText>
                </FormGroup>
              </Col>

              <Col className="pl-1" md="6">
                <FormGroup>
                  <Label> Email</Label>
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email"
                    type="text"
                    name="email"
                    disabled={true} // cannnot be changed
                  />
                  <FormText color="danger" tag="span">
                    {errors.email}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <Label>Security Deposit($)</Label>
                  <Input
                    value={values.securityDeposit}
                    onChange={handleChange}
                    placeholder="Security Deposit"
                    type="text"
                    name="securityDeposit"
                  />
                  <FormText color="danger" tag="span">
                    {errors.securityDeposit}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col className={classes.flexEnd}>
                <Button color="success" type="submit">
                  Update Profile
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      {alert}
    </div>
  );
}

export default Profile;

import { useMutation } from "@apollo/client";
import {
  Box,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { getDriverById } from "../../../Firebase/dao/driverDao";
import { addNotification } from "../../../Firebase/dao/notificationDao";
import {
  addOrderStatusHistory,
  updateOrder,
} from "../../../Firebase/dao/orderDao";
import { addPaymentHistory } from "../../../Firebase/dao/paymentHistoryDao";
import { FirebaseCollections } from "../../../Firebase/Firebase";
import { CancelCharge, ConfirmCharge } from "../../../graphql/mutations";
import { SendSMS } from "../../../graphql/mutations/Twillio";
import {
  Order,
  OrderStatus,
  PaymentStatus,
  Producer,
} from "../../../types/model";
import { costToProducer } from "../../../utils/constants/stripe";
import SweetAlert from "react-bootstrap-sweetalert";
interface LocalOrder extends Order {
  producerData: Producer;
}

const OrderDetails: React.FC<{ order?: LocalOrder; onRefresh: () => void }> = ({
  order,
  onRefresh,
}) => {
  const [openDisputeModal, setOpenDisputeModal] = useState(false);

  const [cancelCharge] = useMutation(CancelCharge);
  const [sendSMS] = useMutation<
    { sendSMS: boolean },
    { input: { to: string; body: string } }
  >(SendSMS);
  const [confirmCharge] = useMutation(ConfirmCharge);

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<React.ReactElement | null>(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const confirmPayment = async (paymentIntentId: string) => {
    //
    await confirmCharge({
      variables: {
        input: {
          paymentIntent: paymentIntentId,
        },
      },
    });
  };

  const driverWinsDispute = async () => {
    try {
      //
      setIsLoading(true);
      if (!order?.selectedBid) return;
      const amount = order?.selectedBid?.price;
      if (!amount) return;
      const distance =
        (order.actualDistanceTravelled ||
        order.selectedBid.vehicleType === "Bike"
          ? order.approximateDistanceBike
          : order.approximateDistanceMotor) / 1000; //conversion from km to m
      const driverPayment = amount * distance;
      if (!order.driver) throw new Error("No driver selected");
      const driver = await getDriverById(order.driver);
      if (!driver) throw new Error("No such driver");

      if (!driver?.stripeAccount) {
        throw new Error(
          "Payment cancelled because driver stripe account didn't exist"
        );
      }

      const totalPayment = costToProducer(driverPayment);
      if (!order.selectedBid.paymentIntent) {
        throw new Error("Payment intent not found!");
      }
      await confirmPayment(order.selectedBid.paymentIntent);

      await updateOrder(order.id!, {
        paymentStatus: PaymentStatus.paid,
        paymentId: order.selectedBid.paymentIntent,
        // review: { rating: rating, review: review },
        paymentProducer: totalPayment,
        paymentDriver: Math.floor(driverPayment * 100),
        updatedDate: new Date(),
      });

      await addPaymentHistory(FirebaseCollections.producer, order.producer, {
        createdAt: new Date(),
        description:
          "Dispute was settled in favor of driver. The order is complete.",
        paymentIntent: order.selectedBid.paymentIntent,
        orderId: order.id!,
        deducted: totalPayment,
      });

      await addPaymentHistory(FirebaseCollections.producer, order.producer, {
        createdAt: new Date(),
        description:
          "Dispute was settled in favor of driver. The order is complete.",
        paymentIntent: order.selectedBid.paymentIntent,
        orderId: order.id!,
        deducted: totalPayment,
      });

      await addOrderStatusHistory(order.id!, {
        date: new Date(),
        orderId: order.id!,
        orderStatus: OrderStatus.delivered,
        paymentStatus: PaymentStatus.paid,
        remark: `Dispute was settled in favor of driver. The order is complete. Order complete with payment intent Id ${order.selectedBid.paymentIntent}`,
      });

      await addNotification(FirebaseCollections.driver, order.driver, {
        createdAt: new Date(),
        message: `Order has been successful. Your account has been credited CA$ ${driverPayment}`,
        seen: false,
      });
      await sendSMS({
        variables: {
          input: {
            to: order.selectedBid.driverPhone,
            body: `Your account has been credited CA$ ${driverPayment}. It will appear in your bank account within two weeks.`,
          },
        },
      });

      await addNotification(FirebaseCollections.producer, order.producer, {
        createdAt: new Date(),
        message: `The order has been successfully completed. You have been charged CA ${
          totalPayment / 100
        }.`,
        seen: false,
      });
      setAlert(
        <SweetAlert
          success
          title="The dispute has been successfully settled."
          onConfirm={hideAlert}
        />
      );
      onRefresh();
    } catch (e) {
      //
      console.log(e);
      setAlert(<SweetAlert error title={e.message} onConfirm={hideAlert} />);
    } finally {
      setOpenDisputeModal(false);
      setIsLoading(false);
    }
  };

  const producerWinsDispute = async () => {
    try {
      //

      setIsLoading(true);
      if (!order?.selectedBid || !order.id) throw new Error("No such order!");
      await cancelCharge({
        variables: {
          id: order.selectedBid.paymentIntent,
        },
      });
      await updateOrder(order.id!, {
        paymentStatus: PaymentStatus.notPaid,
        paymentId: order.selectedBid.paymentIntent,
        // review: { rating: rating, review: review },
        paymentProducer: 0,
        paymentDriver: 0,
        updatedDate: new Date(),
      });
      await addOrderStatusHistory(order.id!, {
        date: new Date(),
        orderId: order.id!,
        orderStatus: OrderStatus.delivered,
        paymentStatus: PaymentStatus.paid,
        remark: `Dispute was settled in favor of producer. The driver doesn't get paid.`,
      });
      await addNotification(FirebaseCollections.driver, order.driver!, {
        createdAt: new Date(),
        message: `You lost dispute.`,
        seen: false,
      });

      await addNotification(FirebaseCollections.producer, order.producer, {
        createdAt: new Date(),
        message:
          "You won the dispute. All the payment has been cancelled/refunded.",
        seen: false,
      });

      await sendSMS({
        variables: {
          input: {
            to: order.selectedBid.driverPhone,
            body: `You have won the dispute. Your payment has been refunded.`,
          },
        },
      });
      setAlert(
        <SweetAlert
          success
          title="The dispute has been successfully settled."
          onConfirm={hideAlert}
        />
      );
      onRefresh();
    } catch (e) {
      //
      console.log(e);
      setAlert(<SweetAlert error title={e.message} onConfirm={hideAlert} />);
    } finally {
      setOpenDisputeModal(false);
      setIsLoading(false);
    }
  };

  const DisputeModal = () => {
    return (
      <Modal
        open={openDisputeModal}
        onClose={() => setOpenDisputeModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}
        >
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Settle Dispute. Please make a selection carefully. This process
                is irreversible.
              </h5>
            </div>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isLoading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner />
                </div>
              )}
              <Button onClick={() => setOpenDisputeModal(false)}>Cancel</Button>
              <Button
                color="success"
                onClick={() => driverWinsDispute()}
                disabled={isLoading}
              >
                Driver wins the dispute
              </Button>
              <Button
                color="warning"
                onClick={() => producerWinsDispute()}
                disabled={isLoading}
              >
                Producer wins the dispute
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  return (
    <div>
      {order && (
        <Grid container>
          <Grid item direction="column" xs={12} lg={6} md={6} sm={12}>
            <Typography gutterBottom variant="h4">
              {order.deliveryType} Order - {order.deliverTo.length} delivery
              location(s)
            </Typography>
            <Typography variant="body2">
              Producer -{" "}
              {`${order.producerData.firstName} ${order.producerData.lastName} | producerId - ${order.producer}`}
              <Button
                tag={Link}
                to={`/admin/producer-profile/${order.producer}`}
                color="info"
                size="md"
                className="btn-link btn-icon"
              >
                <Visibility />
              </Button>
            </Typography>
            <Typography variant="body2" gutterBottom>
              Origin: {order.pickupLocation.address1},
              {order.pickupLocation.city} {order.pickupLocation.postalCode}{" "}
              {order.pickupLocation.province}
            </Typography>
            <List>
              <Typography variant="body2" gutterBottom>
                Destinations:
              </Typography>
              {order.deliverTo.map((d, i) => (
                <List>
                  <ListItem key={i}>
                    <ListItemText
                      primary={`${d.package.number} items | ${d.package.type} | ${d.package.weight}kg`}
                      secondary={`${d.address.address1},${d.address.city}, ${d.address.postalCode}`}
                    />
                  </ListItem>
                </List>
              ))}
            </List>
            {order.selectedBid &&
              (() => {
                //
                const distance =
                  (order?.actualDistanceTravelled ||
                  order?.selectedBid?.vehicleType === "Bike"
                    ? order?.approximateDistanceBike
                    : order?.approximateDistanceMotor) / 1000 || 0;
                const driverPayment =
                  (order.selectedBid?.price || 0) * distance;
                const totalPayment = costToProducer(driverPayment) / 100;
                return (
                  <List>
                    <Typography variant="body2" gutterBottom>
                      Selected Bid:
                    </Typography>

                    <ListItem key={order.selectedBid.bidId}>
                      <Typography>
                        {`CA$${totalPayment} | ${order.selectedBid.driverName} | Driver Id: ${order.selectedBid.driverId}`}
                        <Button
                          tag={Link}
                          to={`/admin/driver-profile/${order.selectedBid.driverId}`}
                          color="info"
                          size="md"
                          className="btn-link btn-icon"
                        >
                          <Visibility />
                        </Button>
                      </Typography>
                    </ListItem>
                  </List>
                );
              })()}
            <Typography variant="body2">
              {order?.bidAvailableToBike &&
                `${(
                  order?.approximateDistanceBike / 1000
                ).toFixed()}km, on bike`}
            </Typography>
            <Typography variant="body2">
              {order?.bidAvailableToMotor &&
                `${(
                  order?.approximateDistanceMotor / 1000
                ).toFixed()}km on motor`}
            </Typography>
            {order.additionalInfo ? (
              <Typography variant="body2" gutterBottom>
                {`Note: ${order.additionalInfo}`}
              </Typography>
            ) : null}

            <Typography gutterBottom variant="subtitle1">
              {order.orderStatus === OrderStatus.driverSelected
                ? "Driver has been chosen."
                : order.orderStatus === OrderStatus.confirmedByDriver
                ? "Driver has confirmed the order."
                : order.orderStatus === OrderStatus.pickedUp
                ? "Driver has picked up the order and you are currently delivering."
                : "Order has been successfully delivered."}
            </Typography>
          </Grid>
          <Grid>
            <Box display="flex" flexDirection="column">
              {order.paymentStatus === PaymentStatus.dispute_in_progress && (
                <>
                  <Box
                    mt={2}
                    display="flex"
                    // className={classes.badge}
                    style={{
                      backgroundColor: "#aaa",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography>Dispute in progress</Typography>
                  </Box>

                  <Typography>Dispute Reason: {order.disputeReason}</Typography>

                  <Button
                    color="primary"
                    onClick={() => setOpenDisputeModal(true)}
                  >
                    Settle Dispute
                  </Button>
                </>
              )}

              {order.review?.rating && (
                <>
                  <Typography>{order.review?.review}</Typography>
                  <Rating
                    name="size-medium"
                    defaultValue={order.review?.rating}
                    readOnly
                  />
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      )}

      <DisputeModal />
      {alert}
    </div>
  );
};

export default OrderDetails;

import { makeStyles } from "@material-ui/core";
// import { ITheme } from "../../../lib/theme";

export const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: theme.palette.primary.light,
    height: "100%",
    minHeight: "20vh",
    width: "100%",
    maxWidth: "calc(100% + 16px)",
    position: "relative",
    justifyContent: "center",
    display: "flex",
    marginBottom: "10px",
  },

  textWrapper: {
    borderBottom: "1px solid",
    // borderColor: theme.palette.text.primary,
    margin: "3rem",
    paddingBottom: "1rem",
    cursor: "pointer",
    // "&:hover": {
    //   color: theme.palette.text.disabled,
    // },
  },
  mainText: {
    fontSize: "2rem",
    // fontWeight:theme.typograph
    // color: theme.palette.text.secondary,
  },
  icons: {
    fontSize: "2rem",
  },
  closeIcon: {
    cursor: "pointer",
  },
  //   login: {
  //     backgroundColor: theme.palette.primary.main,
  //     "&:hover": {
  //       backgroundColor: theme.palette.purple.dark,
  //     },
  //     color: theme.palette.secondary.light,
  //   },
  loginput: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    "& > label": {
      top: "23px",
      left: 0,
      // color: theme.palette.grey[500],
      '&[data-shrink="false"]': {
        top: "5px",
      },
    },
    "& > div > input": {
      padding: "30.5px 14px 11.5px !important",
    },
    "& > div > select": {
      padding: "11.5px 14px !important",
      color: "#66615b",
      fontSize: "14px",
    },
    "& legend": {
      display: "none",
    },
    "& fieldset": {
      top: 0,
    },
  },

  dateinput: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  title: {
    margin: "1rem 0",
    fontWeight: "normal",
    fontSize: "1.5rem",
  },
  info: {
    margin: "auto 5px",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  table: {
    minWidth: 850,
  },
}));

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  FormGroup,
  Modal,
  TextField,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { ProducerData } from "../../../../types/model";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GetStripeSubscription,
  ListCustomerInvoices,
  GetCustomer,
} from "../../../../graphql/queries/stripe";
import moment from "moment";
import { useStyles } from "../../../../styles";
import { BillingReasonsList } from "../../../../constants";
import { UpdateSubscription } from "../../../../graphql/mutations";

interface Props {
  userData: ProducerData;
}

const LIMIT = 10;

const Subscriptions: React.FC<Props> = ({ userData }) => {
  const classes = useStyles();
  const [subscriptionsList, setSubscriptionsList] = useState<any>(null);
  const [cardData, setCardData] = useState<any>(null);
  const [invoiceList, setInvoiceList] = useState<Array<any>>([]);
  const [invoiceQuery, setInvoiceQuery] = useState<any>(null);
  const [lastInvoiceId, setLastInvoiceId] = useState("");
  const [lastPage, setLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [openUpdateTrialModal, setOpenUpdateTrialModal] = useState(false);
  const [trialDate, setTrialDate] = useState(moment(new Date().getTime()).format('YYYY-MM-DD'));
  const [updateSubscription] = useMutation(UpdateSubscription);

  const [requestSubscriptions, { error, loading, data }] = useLazyQuery(
    GetStripeSubscription,
    { variables: { id: userData?.stripeSubscription } }
  );

  const [getInvoices, invoiceData] = useLazyQuery(ListCustomerInvoices, {
    variables: {
      input: invoiceQuery,
    },
  });

  const [getStripeCustomer, customerData] = useLazyQuery(GetCustomer, {
    variables: { id: userData?.stripeCustomer },
  });

  useEffect(() => {
    if (userData?.stripeSubscription && userData?.stripeCustomer) {
      requestSubscriptions();
      getStripeCustomer();
      setInvoiceQuery({
        customer: userData?.stripeCustomer,
        pagination: {
          limit: LIMIT,
        },
      });
    }
  }, [userData]);

  useEffect(() => {
    if (data?.getStripeSubscription?.items?.data?.length > 0) {
      setSubscriptionsList(data?.getStripeSubscription);
    }

    if (customerData?.data?.getCustomer) {
      console.log("customer data ", customerData?.data?.getCustomer);
      setCardData(
        customerData?.data?.getCustomer?.invoice_settings
          ?.default_payment_method?.card
      );
    }
  }, [data, customerData]);

  useEffect(() => {
    if (invoiceData?.data?.listCustomerInvoices) {
      // console.log("customer data", customerData.data)
      const list = invoiceData?.data?.listCustomerInvoices;
      setInvoiceList(list);
      console.log("list", list)
      // console.log(list.slice(-1)[0].id)
      setLastInvoiceId(list.slice(-1)[0]?.id);
      if (list.length <= 10) {
        setLastPage(true);
      }
    }
  }, [invoiceData]);

  useEffect(() => {
    if (invoiceQuery) {
      if (userData?.stripeCustomer) {
        getInvoices();
      }
    }
  }, [invoiceQuery]);

  async function handleUpdateTrial(id: string, timestamp: number) {
    try{
      await updateSubscription({
        variables: {
          input: {
            id: id,
            trial_end: timestamp
          }
        }
      })
      setOpenUpdateTrialModal(false)
      window.location.reload()
    } catch (err) {
      console.log(err)
    }
  }
  
  function hadnleOpenModal() {
    const date = moment(subscriptionsList?.trial_end * 1000).format('YYYY-MM-DD');
    setTrialDate(date);
    setOpenUpdateTrialModal(true);
  }

  function updateTrialModal(id: string) {
    return (
      <Modal
        open={openUpdateTrialModal}
        onClose={() => setOpenUpdateTrialModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}
        >
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Are you sure want to update subscrition trial date ?</h5>
            </div>
            <div style={{ justifyContent: "center", display: "flex", padding: '20px 10px 60px 10px' }}>
              <TextField onChange={(event) => setTrialDate(event.target.value)} fullWidth label="SUBSCRIPTION TRIAL ENDS" type="date" InputLabelProps={{ shrink: true, }} value={trialDate}/>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => setOpenUpdateTrialModal(false)} color="primary" variant="outlined" >
                Cancel
              </Button>
              <Button onClick={() => handleUpdateTrial(id, new Date(trialDate).getTime() / 1000)} color="primary" variant="contained" disableElevation>
                Confirm
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" className={classes.title}>
          Payment Method
        </Typography>

        <Typography
          variant="subtitle1"
          // className={classes.info}
        >
          <Grid container>
            <Grid container item className={classes.flexStart}>
              <Typography variant="subtitle1">Card Number: </Typography>
              <Typography variant="body2" style={{ marginLeft: 5 }}>
                **** **** **** {cardData?.last4} ({cardData?.brand})
              </Typography>
            </Grid>
            <Grid container item className={classes.flexStart}>
              <Typography variant="subtitle1">Card Expiry: </Typography>
              <Typography variant="body2" style={{ marginLeft: 5 }}>
                **/{cardData?.exp_year}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
        <Typography variant="h5" className={classes.title}>
          Subscriptions
        </Typography>
        {subscriptionsList ? (
          <>
            <Typography
              variant="subtitle1"
              // className={classes.info}
            >
              <Grid container>
                <Grid container item style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography variant="subtitle1">
                    { subscriptionsList.items?.data[0]?.price?.product?.description }
                  </Typography>
                  <Box>
                    {updateTrialModal(subscriptionsList.id)}
                    <Button onClick={hadnleOpenModal} color="primary" variant="contained" disableElevation>
                      UPDATE TRIAL MONTH
                    </Button>
                  </Box>
                </Grid>
                <Grid container item className={classes.flexStart}>
                  <Typography variant="subtitle1">
                    Subscription Amount:{" "}
                  </Typography>
                  <Typography variant="body2" style={{ marginLeft: 5 }}>
                    $
                    {subscriptionsList?.items?.data[0]?.price?.unit_amount /
                      100}
                  </Typography>
                </Grid>
                
                <Grid container item className={classes.flexStart}>
                  <Typography variant="subtitle1">
                    Subscription Trial Starts:{" "}
                  </Typography>
                  <Typography variant="body2" style={{ marginLeft: 5 }}>
                    {new Date(
                      subscriptionsList?.trial_start * 1000
                    ).toDateString()}
                  </Typography>
                </Grid>
                <Grid container item className={classes.flexStart}>
                  <Typography variant="subtitle1">
                    Subscription Trial Ends:{" "}
                  </Typography>
                  <Typography variant="body2" style={{ marginLeft: 5 }}>
                    {new Date(
                      subscriptionsList?.trial_end * 1000
                    ).toDateString()}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <TableContainer component={Paper} style={{ marginTop: 40 }}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                   <TableCell>Transaction ID</TableCell>
                    <TableCell>Amount Paid($)</TableCell>
                    <TableCell>Amount Due</TableCell>
                    <TableCell>Billing reason</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceList.map((invoice) => (
                    <TableRow key={invoice.id}>
                       <TableCell>{invoice.id}</TableCell>
                      <TableCell>{invoice.amount_paid}</TableCell>
                      <TableCell>{invoice.amount_due}</TableCell>
                      <TableCell>
                        {invoice?.billing_reason &&
                          BillingReasonsList.find(
                            (reason) => reason.value === invoice.billing_reason
                          )?.label}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {currentPage === 0 ? null : (
                <Button
                  style={{ marginRight: 50 }}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    setInvoiceQuery({
                      customer: userData?.stripeCustomer,
                      pagination: {
                        limit: LIMIT,
                        ending_before: lastInvoiceId,
                      },
                    });
                  }}
                >
                  Previous
                </Button>
              )}
              {lastPage ? null : (
                <Button
                  style={{ marginLeft: 50 }}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    setInvoiceQuery({
                      customer: userData?.stripeCustomer,
                      pagination: {
                        limit: LIMIT,
                        starting_after: lastInvoiceId,
                      },
                    });
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </>
        ) : (
          <Typography
            variant="h5"
            // className={classes.info}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {"User don't have any subscriptions"}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Subscriptions;

import React, { useEffect } from "react";
import { Formik } from "formik";
// reactstrap components
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Select } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  ProducerData,
  ImageType,
  Image,
  Producer,
} from "../../../../types/model";
import { useStyles } from "../../../../styles";
import ImageUpload from "../../../../components/ImageUpload";
import { PROVINCES } from "../../../../constants";
import { storeImage, getImageUrl } from "../../../../Firebase/dao/imageDao";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateProducer } from "../../../../Firebase/dao/producerDao";
interface Props {
  data: ProducerData;
}

function Profile({ data }: Props) {
  console.log(data)
  const classes = useStyles();
  const [governmentId, setGovernmentId] = React.useState<Image>();
  const [imageName, setImageName] = React.useState<any>({
    governmentId: "",
  });
  const [imageError, setImageError] = React.useState({
    governmentId: "",
  });
  const [alert, setAlert] = React.useState<any>(null);

  const hideAlert = () => {
    setAlert(null);
  };
  useEffect(() => {
    if (data.imagePath) {
      getImageUrl(data.imagePath as string).then((url) => {
        setGovernmentId({
          data: url,
        });
      });
    }
  }, [data]);
  return (
    <div className="content">
      <Formik
        initialValues={{
          firstName: data?.firstName,
          lastName: data?.lastName,
          phone: data?.phone,
          email: data?.email,
          address1: data?.addressDetails?.address1 || "",
          address2: data?.addressDetails?.address2 || "",
          postalCode: data?.addressDetails?.postalCode || "",
          city: data?.addressDetails?.city || "",
          province: data?.addressDetails?.province || "",
          country: "Canada",
          submit: "",
        }}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            let updates: Partial<Producer> = {
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              email: values.email,
              addressDetails: {
                address1: values.address1,
                address2: values.address2,
                postalCode: values.postalCode,
                city: values.city,
                province: values.province,
                country: "Canada",
              },
              imagePath: `${data.id}/${ImageType.governmentId}`,
            };
            data.id && (await updateProducer(updates, data.id));
            setAlert(
              <SweetAlert
                success
                title="Driver profile updated successfully"
                onConfirm={hideAlert}
              />
            );
          } catch (error) {
            setAlert(
              <SweetAlert
                error
                title={error.response.message || error.message}
                onConfirm={hideAlert}
              />
            );
          } // values.submit = "Not good";
        }}
        validateOnBlur
        validateOnChange
        validate={async (values) => {
          let errors = {};

          if (!values.firstName) {
            errors = {
              ...errors,
              firstName: "Please enter first Name.",
            };
          }
          if (!values.lastName) {
            errors = { ...errors, lastName: "Please enter last Name." };
          }
          if (!values.phone) {
            errors = { ...errors, phone: "Please enter phone number." };
          } else if (values.phone.length !== 10) {
            errors = { ...errors, phone: "Please enter valid phone number." };
          }

          if (!values.address1) {
            errors = { ...errors, address1: "Please enter address." };
          }
          if (!values.postalCode) {
            errors = {
              ...errors,
              postalCode: "Please enter postal code.",
            };
          }

          if (!values.city) {
            errors = { ...errors, city: "Please enter a city." };
          }
          if (!values.province) {
            errors = {
              ...errors,
              province: "Please select province.",
            };
          }
          if (!values.country) {
            errors = {
              ...errors,
              country: "Please select country.",
            };
          }
          return errors;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            // className={clsx(classes.root, className)}
            // {...rest}
          >
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    value={values.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    type="text"
                    name="firstName"
                  />
                </FormGroup>
                <FormText color="danger" tag="span">
                  {errors.firstName}
                </FormText>
              </Col>

              <Col className="pl-1" md="6">
                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    value={values.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    type="text"
                    name="lastName"
                  />
                </FormGroup>
                <FormText color="danger" tag="span">
                  {errors.lastName}
                </FormText>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    type="text"
                    name="phone"
                  />
                </FormGroup>
                <FormText color="danger" tag="span">
                  {errors.phone}
                </FormText>
              </Col>
              <Col className="pl-1" md="6">
                <FormGroup>
                  <Label> Email</Label>
                  <Input
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email"
                    type="text"
                    disabled={true} // cannnot be changed
                  />
                </FormGroup>
                <FormText color="danger" tag="span">
                  {errors.email}
                </FormText>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <FormGroup>
                  <Label htmlFor="outlined-adornment-email-login">
                    Address Line 1
                  </Label>
                  <Input
                    id="outlined-adornment-address1-login"
                    type="text"
                    value={values.address1}
                    name="address1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />

                  <FormText
                    error
                    id="standard-weight-helper-text-address1-login"
                  >
                    {errors.address1}{" "}
                  </FormText>
                </FormGroup>
              </Col>
              <Col className="pl-1" md="6">
                <Label htmlFor="outlined-adornment-address2-login">
                  Address Line 2 (Optional)
                </Label>
                <FormGroup>
                  <Input
                    id="outlined-adornment-address2-login"
                    type="text"
                    value={values.address2}
                    name="address2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />

                  <FormText
                    error
                    id="standard-weight-helper-text-address2-login"
                  >
                    {errors.address2}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <Label htmlFor="outlined-adornment-postalCode-login">
                  Postal Code
                </Label>
                <FormGroup>
                  <Input
                    id="outlined-adornment-postalCode-login"
                    type="text"
                    value={values.postalCode}
                    name="postalCode"
                    onBlur={handleBlur}
                    v
                    onChange={handleChange}
                  />

                  <FormText
                    error
                    id="standard-weight-helper-text-postalCodelogin"
                  >
                    {errors.postalCode}
                  </FormText>
                </FormGroup>
              </Col>
              <Col className="pl-1" md="6">
                <Label htmlFor="outlined-adornment-city-login">City</Label>
                <FormGroup>
                  <Input
                    id="outlined-adornment-city-login"
                    type="text"
                    value={values.city}
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />

                  <FormText error id="standard-weight-helper-text-city-login">
                    {errors.city}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="6">
                <Label htmlFor="outlined-age-native-simple">Province</Label>
                <FormGroup className={classes.loginput}>
                  <Select
                    native
                    fullWidth
                    variant="outlined"
                    value={values.province}
                    onChange={handleChange}
                    label="province"
                    id="province"
                    name="province"
                  >
                    <option aria-label="None" value="" />
                    {Object.values(PROVINCES).map((province) => (
                      <option value={province} key={province}>
                        {province}
                      </option>
                    ))}
                  </Select>

                  <FormText
                    error
                    id="standard-weight-helper-text-province-login"
                  >
                    {errors.province}
                  </FormText>
                </FormGroup>
              </Col>
              <Col className="pl-1" md="6">
                <Label htmlFor="outlined-adornment-country-login">
                  Country
                </Label>

                <FormGroup>
                  <Input
                    id="outlined-adornment-country-login"
                    type="text"
                    value={values.country}
                    name="country"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />

                  <FormText
                    error
                    id="standard-weight-helper-text-country-login"
                  >
                    {errors.country}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Info />

              <Label className={classes.info}>
                Acceptable government ids are passport, birth certificate,
                citizenship card, permanent resident card, sin, work permit (no
                health cards are accepted)
              </Label>
            </Row>
            <Row>
              <FormGroup>
                {/* <InputLabel>We will need your vehicle's image.</InputLabel> */}

                <ImageUpload
                  image={governmentId?.data}
                  name={`Valid government Id ${
                    imageName.governmentId ? `(${imageName.governmentId})` : ""
                  }`}
                  onChange={(image) => {
                    //console.log(image, name);
                    setGovernmentId(image);
                    setImageName({
                      ...imageName,
                      governmentId: image.name,
                    });
                    setImageError({ ...imageError, governmentId: "" });
                    storeImage(`${data.id}/${ImageType.governmentId}`, image);
                    /****update image */
                  }}
                  id="imageUpload"
                />
                {imageError.governmentId && (
                  <FormText
                    error
                    id="standard-weight-helper-text-canadian-login"
                  >
                    {imageError.governmentId}
                  </FormText>
                )}
              </FormGroup>
            </Row>
            <Row>
              <Col></Col>
              <Col className={classes.flexEnd}>
                <Button color="success" type="submit">
                  Update Profile
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      {alert}
    </div>
  );
}

export default Profile;

type Firebase = {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId?: string
}

type BackEnd = {
  graphQL: {
    BASE_URL: string
  }
}

type EnvStructure = {
  FIREBASE: Firebase
  BACK_END: BackEnd
}

const env: { [name: string]: EnvStructure } = {
  development: {
    FIREBASE: {
      apiKey: "AIzaSyCkYv0fDNSNAaQPt-UxADP-X2hKfv2q1AU",
      authDomain: "carrybou-delivery-dev.firebaseapp.com",
      projectId: "carrybou-delivery-dev",
      storageBucket: "carrybou-delivery-dev.appspot.com",
      messagingSenderId: "648217262414",
      appId: "1:648217262414:web:bc8886c78ba25e05d0061f"
    },
    BACK_END: {
      graphQL: {
        BASE_URL: "https://carrybou-dev-api-248dd9f54352.herokuapp.com/api"
      }
    }
  },
  production: {
    FIREBASE: {
      apiKey: "AIzaSyBzCIapY17NMlSwifmBMjAGCAoWAOa9rXc",
      authDomain: "carrybou-delivery.firebaseapp.com",
      projectId: "carrybou-delivery",
      storageBucket: "carrybou-delivery.appspot.com",
      messagingSenderId: "790975661018",
      appId: "1:790975661018:web:e5d51f112c2474887936e3",
      measurementId: "G-F0CE0C984G",
    },
    BACK_END: {
      graphQL: {
        BASE_URL: "https://carrybou-prod-api-186a7e5286ae.herokuapp.com/api"
      }
    }
  }
}
const environmentResolver = process.env.REACT_APP_ENV as 'production' | 'development' || 'development';
console.log('Environment(Web): %s', environmentResolver);
export const environment = env[environmentResolver];
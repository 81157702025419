import { gql } from "@apollo/client";

export const getStripeAccount = gql`
  query getAccount($id: String!) {
    getAccount(id: $id) {
      id
    }
  }
`;

export const ListStripePrices = gql`
  query listStripePrices {
    listStripePrices {
      id
      currency
      # product{
      #     name
      #     description
      # }
      nickname
    }
  }
`;

export const GetStripeSubscription = gql`
  query getStripeSubscription($id: String!) {
    getStripeSubscription(id: $id) {
      id
      items {
        url
        object
        data {
          id
          object
          price {
            id
            currency
            product {
              id
              name
              description
            }
            unit_amount
            active
          }
        }
      }
      latest_invoice
      trial_start
      trial_end
    }
  }
`;

export const ListCustomerInvoices = gql`
  query listCustomerInvoices($input: inputCustomerInvoices!) {
    listCustomerInvoices(input: $input) {
      id
      account_name
      amount_paid
      billing_reason
      charge
      customer_phone
      application_fee_amount
      amount_due
      attempt_count
      charge
    }
  }
`;

export const GetCustomer = gql`
  query getCustomer($id: String) {
    getCustomer(id: $id) {
      id
      payment_method {
        brand
        country
      }
      invoice_settings {
        default_payment_method {
          id
          card {
            brand
            country
            exp_year
            exp_month
            last4
          }
          billing_details {
            address {
              country
              line1
              postal_code
            }
            email
            phone
          }
        }
      }
    }
  }
`;

import {
  Bid,
  Order,
  OrderStatus,
  OrderStatusHistory,
  PaymentStatus,
  VehicleType,
} from "../../types/model";
import firebase, {
  driverCollection,
  FirebaseCollections,
  orderCollection,
} from "../Firebase";

/**
 * CREATE ORDER
 * @param order
 * @returns
 */
export const createOrder = async (order: Order): Promise<Order> => {
  const orderRef = await orderCollection.add(order);

  return {
    ...order,
    id: orderRef.id,
  };
};

/**
 *
 * @param docRef
 * @returns
 */
export const getOrderById = (docRef: string): Promise<Order> => {
  return orderCollection
    .doc(docRef)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: doc.id } as Order;
      } else {
        throw new Error(`No Order of order id ${docRef} found`);
      }
    });
};

/**
 * Update an order
 * @param orderId
 * @param order
 */
export const updateOrder = async (
  orderId: string,
  order: Partial<Order>
): Promise<void> => {
  await orderCollection.doc(orderId).update(order);
};

/**
 * Retrieve all orders made by producer. for now no pagination. but we will add pagination later.
 * @param producerId
 * @returns orders
 */
export const getProducerOrders = async (
  producerId: string
): Promise<Array<Order>> => {
  return await orderCollection
    // .where("orderStatus", "<" , OrderStatus.delivered)
    .where("producer", "==", producerId)
    .get()
    .then((querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach(async (doc) => {
        const id = doc.id;
        const data = doc.data() as Order;
        data.orderStatus < OrderStatus.delivered &&
          orders.push({
            id: id,
            ...data,
          });
        // for (const order of orders) {
        //   const allBids = await getAllBidsOfOrder(order.id);
        //   order.bids = allBids;
        // }
        // return orders;
      });
      return orders;
    });
};

/**
 * Retrieve all completed orders made by producer. for now no pagination. but we will add pagination later.
 * @param producerId
 * @returns orders
 */
export const getProducerPastOrders = async (
  producerId: string
): Promise<Array<Order>> => {
  return await orderCollection
    .where("orderStatus", "==", OrderStatus.delivered)
    .where("producer", "==", producerId)
    .get()
    .then((querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach(async (doc) => {
        const id = doc.id;
        const data = doc.data() as Order;
        // data.orderStatus < OrderStatus.delivered &&
        orders.push({
          id: id,
          ...data,
        });
        // for (const order of orders) {
        //   const allBids = await getAllBidsOfOrder(order.id);
        //   order.bids = allBids;
        // }
        // return orders;
      });
      return orders;
    });
};

/**
 * Retrieve all orders for a driver.
 * @param producerId
 * @returns orders
 */
export const getAllDriversOrder = async (
  driverId: string
): Promise<Array<Order>> => {
  return await orderCollection
    .where("driver", "==", driverId)
    .get()
    .then((querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data() as Order;
        orders.push({
          id: id,
          ...data,
        });
      });
      return orders;
    });
};

/**
 * Retrieve all Open orders
 * @returns orders
 */
export const getCurrentOrders = async (): Promise<Array<Order>> => {
  return orderCollection
    .where("paymentStatus", "in", [
      PaymentStatus.orderInProgress,
      PaymentStatus.pending,
    ])
    .orderBy("updatedDate")
    .get()
    .then(async (querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        orders.push({
          id: id,
          ...data,
          updatedDate: data.updatedDate?.toDate() || null,
          createdDate: data.createdDAte?.toDate() || null,
        } as Order);
      });
      return orders;
    });
};
export const getCompletedOrders = async (): Promise<Array<Order>> => {
  return orderCollection
    .where("paymentStatus", "==", PaymentStatus.paid)
    .orderBy("updatedDate")
    .get()
    .then(async (querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        orders.push({
          id: id,
          ...data,
          updatedDate: data.updatedDate?.toDate() || null,
          createdDate: data.createdDAte?.toDate() || null,
        } as Order);
      });
      return orders;
    });
};
export const getOrdersInDispute = async (): Promise<Array<Order>> => {
  return orderCollection
    .where("paymentStatus", "in", [
      PaymentStatus.error,
      PaymentStatus.dispute_in_progress,
    ])
    .orderBy("updatedDate")
    .get()
    .then(async (querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        orders.push({
          id: id,
          ...data,
          updatedDate: data.updatedDate?.toDate() || null,
          createdDate: data.createdDAte?.toDate() || null,
        } as Order);
      });
      return orders;
    });
};
export const getUnpaidOrders = async (): Promise<Array<Order>> => {
  return orderCollection
    .where("paymentStatus", "==", PaymentStatus.notPaid)
    .orderBy("updatedDate")
    .get()
    .then(async (querySnapshot) => {
      const orders: Array<Order> = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        orders.push({
          id: id,
          ...data,
          updatedDate: data.updatedDate?.toDate() || null,
          createdDate: data.createdDAte?.toDate() || null,
        } as Order);
      });
      return orders;
    });
};

/**
 * get all bids of an order
 * @param orderId
 * @returns
 */
export const getAllBidsOfOrder = async (
  orderId: string
): Promise<Array<Bid>> => {
  return orderCollection
    .doc(orderId)
    .collection(FirebaseCollections.bids)
    .get()
    .then((querySnapshot) => {
      const orders: Array<Bid> = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data() as Bid;
        orders.push({ ...data, bidId: doc.id });
      });
      return orders;
    });
};

export const getOrderStatusHistory = async (
  orderId: string
): Promise<Array<OrderStatusHistory>> => {
  return orderCollection
    .doc(orderId)
    .collection(FirebaseCollections.orderStatusHistory)
    .orderBy("date")
    .get()
    .then((querySnapshot) => {
      const orders: Array<OrderStatusHistory> = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        orders.push({
          ...data,
          date: data.date?.toDate(),
        } as OrderStatusHistory);
      });
      return orders;
    });
};

/**
 * Add order status history
 * @param orderId
 * @param history
 */
export const addOrderStatusHistory = async (
  orderId: string,
  history: OrderStatusHistory
): Promise<void> => {
  await orderCollection.doc(orderId).collection("history").add(history);
};

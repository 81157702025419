import { async } from "q";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import {
  Visibility,
  Mail,
  Clear,
} from "@material-ui/icons";
import Table from "../../../components/ReactTable/Table";
import { DriverData } from "../../../types/model";
import { Link } from "react-router-dom";
import {
  fetchPendingApproval,
  fetchIncompleteRegistration,
  fetchInactive,
  fetchActive,
  fetchDisabled,
  updateDriver,
} from "../../../Firebase/dao/driverDao";
import moment from "moment";
import { Modal } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { addNotification } from "../../../Firebase/dao/notificationDao";
import { FirebaseCollections } from "../../../Firebase/Firebase";

const AllDrivers = () => {
  const [pageTabs, setPageTabs] = useState("pendingApproval");
  const [pendingApproval, setPendingApproval] = useState<Array<DriverData>>([]);
  const [incompleteRegistration, setIncompleteRegistration] = useState<
    Array<DriverData>
  >([]);
  const [inActive, setInActive] = useState<Array<DriverData>>([]);
  const [active, setActive] = useState<Array<DriverData>>([]);
  const [disabled, setDisabled] = useState<Array<DriverData>>([]);

  const [openModal, setOpenModal] = useState({
    acceptReject: false,
  });
  const [alert, setAlert] = useState<any>(null);
  const [actionType, setActionType] = useState<any>(null);
  const [userId, setUserId] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: "FirstName",
        accessor: "firstName", // accessor is the "key" in the data
      },
      {
        Header: "LastName",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Vehicle Type",
        accessor: "vehicleType",
      },
      {
        Header: "Security Deposit(CAD)",
        accessor: "securityDeposit",
      },
      {
        Header: "Active",
        accessor: "userActive",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
        width: 160,
      },
    ],
    []
  );

  useEffect(() => {
    fetchDrivers();
  }, [pageTabs]);

  const fetchDrivers = async () => {
    try {
      const data =
        pageTabs === "pendingApproval"
          ? await fetchPendingApproval()
          : pageTabs === "incompletedRegistration"
          ? await fetchIncompleteRegistration()
          : pageTabs === "inActive"
          ? await fetchInactive()
          : pageTabs === "active"
          ? await fetchActive()
          : await fetchDisabled();
      const tempData = data.map((d) => {
        return {
          ...d,
          securityDeposit:
            d.securityDeposit && parseInt(`${d?.securityDeposit / 100}`),
          actions: RenderActionButtons(d?.id as string),
          userActive: d?.lastUserActivity
            ? moment(new Date(d?.lastUserActivity)).isAfter(
                moment().subtract(15, "days")
              )
              ? "true"
              : "false"
            : "false",
        };
      });
      console.log("drivers data", tempData);
      pageTabs === "pendingApproval"
        ? setPendingApproval(tempData)
        : pageTabs === "incompletedRegistration"
        ? setIncompleteRegistration(tempData)
        : pageTabs === "inActive"
        ? setInActive(tempData)
        : pageTabs === "active"
        ? setActive(tempData)
        : setDisabled(tempData);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderActionButtons = (id: string) => {
    return (
      <div className="actions-right">
        {pageTabs === "pendingApproval" && (
          <Button
            onClick={() => handleOpenAcceptRejectModal("accept", id)}
            color="warning"
            size="md"
            className="btn-link btn-icon"
          >
            <Mail style={{ marginLeft: -1 }} />
          </Button>
        )}
        <Button
          tag={Link}
          to={`/admin/driver-profile/${id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>

        {pageTabs === "pendingApproval" && (
          <Button
            onClick={() => handleOpenAcceptRejectModal("reject", id)}
            color="danger"
            size="md"
            className="btn-link btn-icon"
          >
            <Clear style={{ marginLeft: -1 }} />
          </Button>
        )}
      </div>
    );
  };

  // Opens modal to Accept/Reject user account
  const handleOpenAcceptRejectModal = (type: any, id: string) => {
    setOpenModal({ acceptReject: true });
    setActionType(type);
    setUserId(id);
  };

  const handleCloseAcceptRejectModal = () => {
    setOpenModal({ acceptReject: false });
    setActionType(null);
    setUserId("");
  };

  const hideAlert = () => {
    setAlert(null);
  };

  ////////////////////////////////////////////////////
  // ACCEPT/REJECT USER
  // Popup for ACCEPT/REJECT user from database
  ////////////////////////////////////////////////////
  const acceptRejectModal = () => {
    return (
      <Modal
        open={openModal.acceptReject}
        onClose={handleCloseAcceptRejectModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}
        >
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>
                Are you sure want to{" "}
                {actionType === "accept" ? "Accept" : "Reject"} driver ?
              </h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseAcceptRejectModal()}>
                Cancel
              </Button>
              <Button
                color={actionType === "accept" ? "success" : "danger"}
                onClick={() => handleUpdate()}
              >
                {actionType === "accept" ? "Accept" : "Reject"}
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  // update user applicatcion
  const handleUpdate = async () => {
    try {
      let update: Partial<DriverData> = {};
      if (actionType === "accept") {
        update = {
          approved: true,
          driverWaitingForApproval: false,
        };
      } else {
        update = {
          approved: false,
          driverWaitingForApproval: false,
        };
      }
      await updateDriver(userId, update);
      await addNotification(FirebaseCollections.driver,userId, {
        message: actionType === "accept" ? "Congratulations, your driver application is approved" : "Sorry, the Carrybou Administrators have rejected your Driver's Application. Thank you for your time",
        seen: false,
        createdAt: new Date()
      })
      handleCloseAcceptRejectModal();
      setAlert(
        <SweetAlert
          success
          title="Driver updated successfully."
          onConfirm={hideAlert}
        />
      );
      fetchDrivers();
    } catch (err) {
      handleCloseAcceptRejectModal();
      setAlert(
        <SweetAlert
          error
          title={err.response.message || err.message}
          onConfirm={hideAlert}
        />
      );
    }
  };

  //Renders the nav for each of the tabs. Title and Name are strings
  const renderNavItem = (tabTitle: string, tabId: string) => {
    return (
      <NavItem>
        <NavLink
          data-toggle="tab"
          role="tablist"
          className={pageTabs === tabId ? "active" : ""}
          onClick={() => setPageTabs(tabId)}
        >
          <i className="now-ui-icons ui-2_settings-90" />
          {tabTitle}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">All Drivers</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                  pills
                  role="tablist"
                >
                  {renderNavItem(`Pending Approval`, "pendingApproval")}
                  {renderNavItem(
                    `Incomplete Registration`,
                    "incompletedRegistration"
                  )}
                  {renderNavItem(`Pending Deposit/Inactive`, "inActive")}
                  {renderNavItem(`Active`, "active")}
                  {renderNavItem(`Disabled/Deleted Account`, "disabled")}
                </Nav>
                <Table
                  data={
                    pageTabs === "pendingApproval"
                      ? pendingApproval
                      : pageTabs === "incompletedRegistration"
                      ? incompleteRegistration
                      : pageTabs === "inActive"
                      ? inActive
                      : pageTabs === "active"
                      ? active
                      : disabled
                  }
                  columns={columns}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert}
        {acceptRejectModal()}
      </div>
    </>
  );
};

export default AllDrivers;

import React, { useState, useEffect } from "react";
import { Formik } from "formik";
// reactstrap components
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Info } from "@material-ui/icons";
import { Driver, Image, ImageType, FuelType } from "../../../../types/model";
import ImageUpload from "../../../../components/ImageUpload";
import { Select } from "@material-ui/core";
import { pastNYears } from "../../../../utils/date";
import { getImageUrl, storeImage } from "../../../../Firebase/dao/imageDao";
import { useStyles } from "../../../../styles";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateDriver } from "../../../../Firebase/dao/driverDao";

interface Props {
  data: Driver;
}

const fuelTypes = ["Diesel", "EV", "Gasoline"];

const years = pastNYears(25);

function Vehicle({ data }: Props) {
  console.log(data);
  const classes = useStyles();
  const [imageName, setImageName] = React.useState<any>({
    insurancePermit: "",
    governmentId: "",
    driversLicense: "",
  });
  const [imageError, setImageError] = React.useState({
    insurancePermit: "",
    governmentId: "",
    driversLicense: "",
  });
  const [alert, setAlert] = useState<any>(null);

  const [insurancePermit, setInsurancePermit] = React.useState<Image>();
  const [governmentId, setGovernmentId] = React.useState<Image>();
  const [driversLicense, setDriversLicense] = React.useState<Image>();

  useEffect(() => {
    if (data?.vehicleDetails) {
      if (data.vehicleDetails.driversLicense) {
        getImageUrl(data.vehicleDetails.driversLicense as string).then(
          (url) => {
            setDriversLicense({
              data: url,
            });
          }
        );
      }
      if (data.vehicleDetails.governmentId) {
        getImageUrl(data.vehicleDetails.governmentId as string).then((url) => {
          setGovernmentId({
            data: url,
          });
        });
      }
      if (data.vehicleDetails.insurancePermit) {
        getImageUrl(data.vehicleDetails.insurancePermit as string).then(
          (url) => {
            setInsurancePermit({
              data: url,
            });
          }
        );
      }
    }
  }, [data]);

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div className="content">
      <Formik
        initialValues={{
          vehicleType: data.vehicleType,
          year: data?.vehicleDetails?.year,
          make: data?.vehicleDetails?.make,
          model: data?.vehicleDetails?.model,
          vin: data?.vehicleDetails?.vin,
          color: data?.vehicleDetails?.color,
          licensePlate: data?.vehicleDetails?.licensePlate,
          fuelType: data?.vehicleDetails?.fuelType,
          submit: "",
        }}
        enableReinitialize
        onSubmit={async (values) => {
          console.log(values);
          try {
            let updates: Partial<Driver> = {
              vehicleType: values.vehicleType,
            };
            if (values.vehicleType === "Motor") {
              updates = {
                ...updates,
                vehicleDetails: {
                  year: values.year,
                  make: values.make,
                  model: values.model,
                  vin: values.vin,
                  color: values.color,
                  licensePlate: values.licensePlate,
                  fuelType: values.fuelType as FuelType,
                  insurancePermit: `${data.id}/${ImageType.insurancePermit}`,
                  driversLicense: `${data.id}/${ImageType.driversLicense}`,
                  governmentId: `${data.id}/${ImageType.governmentId}`,
                },
              };
            } else if (values.vehicleType === "Bike") {
              updates = {
                ...updates,
                vehicleDetails: {
                  governmentId: `${data.id}/${ImageType.governmentId}`,
                },
              };
            }
            console.log(updates);
            data.id && (await updateDriver(data.id, updates));
            setAlert(
              <SweetAlert
                success
                title="vehicle details updated successfully"
                onConfirm={hideAlert}
              />
            );
          } catch (error) {
            setAlert(
              <SweetAlert
                error
                title={error.response.message || error.message}
                onConfirm={hideAlert}
              />
            );
          }
        }}
        validateOnBlur
        validateOnChange
        validate={async (values) => {
          let errors = {};
          console.log(values);
          console.log(insurancePermit);
          let imageErrors = {
            insurancePermit: "",
            governmentId: "",
            driversLicense: "",
          };

          if (!values.vehicleType) {
            errors = {
              ...errors,
              vehicleType: "Please select vehicle type.",
            };
          }

          if (values.vehicleType === "Motor" && !values.year) {
            // setErrors({ email: "Please enter an email." });
            errors = { ...errors, year: "Please enter a year." };
          }
          if (values.vehicleType === "Motor" && !values.make) {
            errors = { ...errors, make: "Please enter make." };
          }
          if (values.vehicleType === "Motor" && !values.model) {
            errors = { ...errors, model: "Please enter model." };
          }

          if (values.vehicleType === "Motor" && !values.vin) {
            errors = {
              ...errors,
              vin: "Please enter a VIN (Vehicle ID Number).",
            };
          }
          if (values.vehicleType === "Motor" && !values.color) {
            errors = { ...errors, color: "Please enter a color." };
          }
          if (values.vehicleType === "Motor" && !values.licensePlate) {
            errors = {
              ...errors,
              licensePlate: "Please enter a license plate number.",
            };
          }
          if (values.vehicleType === "Motor" && !insurancePermit) {
            imageErrors = {
              ...imageErrors,
              insurancePermit:
                "Please upload your commercial insurance permit.",
            };
          }
          if (values.vehicleType === "Motor" && !driversLicense) {
            imageErrors = {
              ...imageErrors,
              driversLicense: "Please upload your driver license.",
            };
          }

          if (!governmentId) {
            imageErrors = {
              ...imageErrors,
              governmentId: "Please upload your valid government ID.",
            };
          }
          setImageError(imageErrors);
          return errors;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            // className={clsx(classes.root, className)}
            // {...rest}
          >
            <>
              <Row>
                <Col md="3"></Col>
                <Col className="pr-1" md="6">
                  <FormGroup className={classes.loginput}>
                    <Label>Vehicle Type</Label>
                    <Select
                      native
                      fullWidth
                      variant={"outlined"}
                      value={values.vehicleType}
                      onChange={handleChange}
                      label="vehicleType"
                      id="vehicleType"
                      name="vehicleType"
                    >
                      <option aria-label="None" value="" />
                      <option value={"Bike"}>Bike</option>
                      <option value={"Motor"}>Motor</option>
                    </Select>
                  </FormGroup>
                  <FormText color="danger" tag="span">
                    {errors.vehicleType}
                  </FormText>
                </Col>
                <Col md="3"></Col>
              </Row>
              {values.vehicleType === "Motor" && (
                <>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup className={classes.loginput}>
                        <Label>Year</Label>
                        <Select
                          variant="outlined"
                          fullWidth
                          native
                          type="number"
                          value={values.year}
                          onChange={handleChange}
                          id="year"
                          name="year"
                        >
                          <option aria-label="None" value="" />
                          <>
                            {years.map((year) => (
                              <option value={year} key={year}>
                                {year}
                              </option>
                            ))}
                          </>
                        </Select>
                        {touched.year && errors.year && (
                          <FormText color="danger" tag="span">
                            {errors.year}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup className={classes.loginput}>
                        <Label>Fuel Type</Label>
                        <Select
                          native
                          fullWidth
                          value={values.fuelType}
                          onChange={handleChange}
                          label="fuelType"
                          id="fuelType"
                          name="fuelType"
                          variant="outlined"
                        >
                          <option aria-label="None" value="" />
                          <>
                            {fuelTypes.map((type) => (
                              <option value={type} key={type}>
                                {type}
                              </option>
                            ))}
                          </>
                        </Select>
                        {touched.fuelType && errors.fuelType && (
                          <FormText color="danger" tag="span">
                            {errors.fuelType}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <Label>Model</Label>
                        <Input
                          id="model"
                          type="text"
                          value={values.model}
                          name="model"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.model && errors.model && (
                          <FormText color="danger" tag="span">
                            {errors.model}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <Label>VIN (Vehicle ID Number)</Label>
                        <Input
                          id="vin"
                          type="text"
                          value={values.vin}
                          name="vin"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.vin && errors.vin && (
                          <FormText color="danger" tag="span">
                            {errors.vin}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <Label>Make</Label>
                        <Input
                          id="outlined-adornment-make-login"
                          type="text"
                          value={values.make}
                          name="make"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.make && errors.make && (
                          <FormText color="danger" tag="span">
                            {errors.make}{" "}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <Label>License Plate</Label>
                        <Input
                          id="licensePlate"
                          type="text"
                          value={values.licensePlate}
                          name="licensePlate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.licensePlate && errors.licensePlate && (
                          <FormText color="danger" tag="span">
                            {errors.licensePlate}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <Label htmlFor="outlined-adornment-email-login">
                          Color
                        </Label>
                        <Input
                          id="color"
                          type="text"
                          value={values.color}
                          name="color"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        {touched.color && errors.color && (
                          <FormText color="danger" tag="span">
                            {errors.color}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <FormGroup className={classes.flex}>
                      {/* <InputLabel>We will need your vehicle's image.</InputLabel> */}

                      <ImageUpload
                        image={driversLicense?.data}
                        onChange={(image) => {
                          //console.log(image, name);
                          setDriversLicense(image);
                          setImageName({
                            ...imageName,
                            driversLicense: image.name,
                          });
                          setImageError({
                            ...imageError,
                            driversLicense: "",
                          });
                          storeImage(
                            `${data.id}/${ImageType.driversLicense}`,
                            image
                          );
                        }}
                        id={"Driver's License"}
                        name={`Driver's License ${
                          imageName.driversLicense
                            ? `(${imageName.driversLicense})`
                            : ""
                        }`}
                      />

                      <FormText color="danger" tag="span">
                        {imageError.driversLicense}
                      </FormText>
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup className={classes.flex}>
                      {/* <InputLabel>We will need your vehicle's image.</InputLabel> */}

                      <ImageUpload
                        image={insurancePermit?.data}
                        onChange={(image) => {
                          //console.log(image, name);
                          setInsurancePermit(image);
                          setImageName({
                            ...imageName,
                            insurancePermit: image.name,
                          });
                          setImageError({
                            ...imageError,
                            insurancePermit: "",
                          });
                          storeImage(
                            `${data.id}/${ImageType.insurancePermit}`,
                            image
                          );
                        }}
                        id="insurance permit"
                        name={`insurance permit ${
                          imageName.insurancePermit
                            ? `(${imageName.insurancePermit})`
                            : ""
                        }`}
                      />

                      <FormText color="danger" tag="span">
                        {imageError.insurancePermit}
                      </FormText>
                    </FormGroup>
                  </Row>
                </>
              )}
              <Row>
                <Label>
                  {values.vehicleType === "Motor" ? (
                    <div>
                      <Info />
                      {"  "}
                      Acceptable government IDs are: passport, birth
                      certificate, citizenship card, permanent resident card,
                      sin, and work permit.
                    </div>
                  ) : (
                    <div>
                      <Info />
                      {"  "}
                      {`Acceptable government IDs are: passport, birth
                      certificate, citizenship card, permanent resident card,
                      sin, work permit, and driver's license.`}
                    </div>
                  )}
                </Label>
              </Row>

              <Row>
                <FormGroup className={classes.flex}>
                  <ImageUpload
                    image={governmentId?.data}
                    onChange={(image) => {
                      //console.log(image, name);
                      setGovernmentId(image);
                      setImageName({
                        ...imageName,
                        governmentId: image.name,
                      });
                      setImageError({ ...imageError, governmentId: "" });
                      storeImage(`${data.id}/${ImageType.governmentId}`, image);
                    }}
                    id={"Valid government Id"}
                    name={`Valid government Id ${
                      imageName.governmentId
                        ? `(${imageName.governmentId})`
                        : ""
                    }`}
                  />

                  <FormText color="danger" tag="span">
                    {imageError.governmentId}
                  </FormText>
                </FormGroup>
              </Row>
              <Row>
                <Col></Col>
                <Col className={classes.flexEnd}>
                  <Button color="success" type="submit">
                    Update vehicle Info
                  </Button>
                </Col>
              </Row>
            </>
          </form>
        )}
      </Formik>
      {alert}
    </div>
  );
}

export default Vehicle;

import React from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./assets/scss/paper-dashboard.scss";
import "./assets/scss/paper-kit.scss";
import AuthProvider from "./context/AdminContext";
import AppProvider from "./context/AppContext";
import Admin from "./layouts/Admin";
import Error404 from "./views/pages/Error404";
import ForgotPassword from "./views/pages/ForgotPassword";
import Login from "./views/pages/Login";
import { environment } from "./config/env";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: environment.BACK_END.graphQL.BASE_URL
  // link: from([authMiddleware]),
});

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <AppProvider>
          <AuthProvider>
            <BrowserRouter>
              <Switch>
                {/* Landing Page for staffing home page */}
                <Route
                  path="/"
                  exact
                  render={(props) => <Redirect to="login" />}
                />
                <Route
                  path="/login"
                  exact
                  render={(props) => <Login {...props} />}
                />
                <Route
                  path="/forgot-password"
                  exact
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route path="/admin" render={(props) => <Admin {...props} />} />
                <Route render={(props) => <Error404 />} />
              </Switch>
            </BrowserRouter>
          </AuthProvider>
        </AppProvider>
      </ApolloProvider>
    </>
  );
}

export default App;

// reactstrap components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  getOrderById,
  getOrderStatusHistory,
} from "../../Firebase/dao/orderDao";
import { fetchProducer } from "../../Firebase/dao/producerDao";
import { Order, OrderStatusHistory, Producer } from "../../types/model";
import OrderDetails from "./DeliveryProfile/OrderDetails";
import OrderTimeLine from "./DeliveryProfile/OrderTimeLine";

enum PageTabs {
  orderDetails = "Order Details",
  orderTimeline = "Order TimeLine",
}

interface LocalOrder extends Order {
  producerData: Producer;
}

const DriverProfile: React.FC = ({ ...rest }) => {
  const [pageTabs, setPageTabs] = useState(PageTabs.orderDetails);
  const [order, setOrder] = useState<LocalOrder>();
  const [orderStatusHistory, setOrderStatusHistory] = useState<
    Array<OrderStatusHistory>
  >([]);
  const [error, setError] = useState("");
  const { id } = useParams<{ id: string }>();
  // const { createAlert } = useAppContext();

  const fetchOrder = async (id: string) => {
    try {
      const order = await getOrderById(id);
      const producer = await fetchProducer(order.producer);
      setOrder({ ...order, producerData: producer });
      setError("");
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
  };
  const fetchOrdertimeLine = async (id: string) => {
    try {
      const orderStatus = await getOrderStatusHistory(id);
      setOrderStatusHistory(orderStatus);
      setError("");
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
  };

  useEffect(() => {
    id &&
      pageTabs === PageTabs.orderTimeline &&
      !orderStatusHistory.length &&
      fetchOrdertimeLine(id);
    id && pageTabs === PageTabs.orderDetails && !order && fetchOrder(id);
  }, [id, pageTabs]);

  const MyNavItem = ({ tabTitle }: { tabTitle: PageTabs }) => {
    return (
      <NavItem>
        <NavLink
          data-toggle="tab"
          role="tablist"
          className={pageTabs === tabTitle ? "active" : ""}
          onClick={() => setPageTabs(tabTitle)}
        >
          <i className="now-ui-icons ui-2_settings-90" />
          {tabTitle}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="no-transition">
            <CardHeader>
              <Nav
                className="nav-pills-primary nav-pills-icons justify-content-center"
                pills
                role="tablist"
              >
                {(Object.keys(PageTabs) as Array<keyof typeof PageTabs>).map(
                  (key) => {
                    return <MyNavItem tabTitle={PageTabs[key]} />;
                  }
                )}
              </Nav>
            </CardHeader>
            <CardBody>
              {error}
              <TabContent
                className="tab-space tab-subcategories"
                activeTab={pageTabs}
              >
                <TabPane tabId={PageTabs.orderDetails}>
                  <OrderDetails
                    order={order}
                    onRefresh={() => {
                      fetchOrder(id);
                    }}
                  />
                </TabPane>
                <TabPane tabId={PageTabs.orderTimeline}>
                  <OrderTimeLine timeLine={orderStatusHistory} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DriverProfile;

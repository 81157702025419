import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DriverData, SecurityDepositHistory } from "../../../../types/model";
import { useLazyQuery } from "@apollo/client";
import { GetCustomer } from "../../../../graphql/queries/stripe";
import { useStyles } from "../../../../styles";
// reactstrap components
import {
  Button,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Formik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getSecurityDepositHistory,
  updateDriver,
  addSecurityDepositHistory,
} from "../../../../Firebase/dao/driverDao";
import Table from "../../../../components/ReactTable/Table";
import moment from "moment";
import { Select } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

interface Props {
  userData: DriverData;
}

interface SecurityDepositHistoryModified extends SecurityDepositHistory {
  type?: string;
  amount?: number;
  displayDate?: string;
}

const Payment: React.FC<Props> = ({ userData }) => {
  // console.log(userData);
  const classes = useStyles();
  const [securityDeposit, setSecurityDeposit] = useState(
    userData?.securityDeposit ? userData.securityDeposit / 100 : 0
  );
  const [cardData, setCardData] = useState<any>(null);
  const [alert, setAlert] = useState<any>(null);
  const [edit, setEdit] = useState(false);
  const [securityDepositHistory, setSecurityDepositHistory] = useState<
    Array<SecurityDepositHistoryModified>
  >([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Amount ($)",
        accessor: "amount", // accessor is the "key" in the data
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Reason",
        accessor: "description",
      },
      {
        Header: "Date",
        accessor: "displayDate",
      },
    ],
    []
  );

  const hideAlert = () => {
    setAlert(null);
  };

  const [getStripeCustomer, customerData] = useLazyQuery(GetCustomer, {
    variables: { id: userData?.stripeCustomer },
  });

  useEffect(() => {
    if (userData?.stripeCustomer) {
      getStripeCustomer();
      fetchSecurityDepositHistory();
    }
  }, [userData]);

  useEffect(() => {
    if (customerData?.data?.getCustomer) {
      console.log("customer data ", customerData?.data?.getCustomer);
      setCardData(
        customerData?.data?.getCustomer?.invoice_settings
          ?.default_payment_method?.card
      );
    }
  }, [customerData]);

  // fetch all security deposit history
  const fetchSecurityDepositHistory = async () => {
    try {
      const data = await getSecurityDepositHistory(userData?.id as string);
      const history = data.map((d) => {
        return {
          ...d,
          displayDate: moment(d.date).calendar(),
          amount:
            (d?.deposit && d?.deposit / 100) ||
            (d?.deducted && d?.deducted / 100) ||
            (d?.refunded && d?.refunded / 100), // convert to dollars
          type: d.deposit ? "Deposited" : d.refunded ? "Refunded" : "Deducted",
        };
      });
      console.log("history", history);
      setSecurityDepositHistory(history);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="content">
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h5" className={classes.title}>
            Payment Method
          </Typography>

          <Typography
            variant="subtitle1"
            // className={classes.info}
          >
            <Grid container>
              <Grid container item className={classes.flexStart}>
                <Typography variant="subtitle1">Card Number: </Typography>
                <Typography variant="body2" style={{ marginLeft: 5 }}>
                  **** **** **** {cardData?.last4} ({cardData?.brand})
                </Typography>
              </Grid>
              <Grid container item className={classes.flexStart}>
                <Typography variant="subtitle1">Card Expiry: </Typography>
                <Typography variant="body2" style={{ marginLeft: 5 }}>
                  **/{cardData?.exp_year}
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Grid>
      </Grid>
      <Grid container item className={classes.flexStart}>
        <Typography variant="h5">Security Deposit History </Typography>
      </Grid>
      <Formik
        initialValues={{
          amount: 0,
          securityDeposit: securityDeposit,
          description: "",
          type: "deduct",
          submit: "",
        }}
        enableReinitialize
        onSubmit={async (values) => {
          try {
            if (userData.id) {
              const updates: SecurityDepositHistoryModified = {
                description: values.description,
                date: new Date(),
              };
              if (values.type === "deduct") {
                updates.deducted = values.securityDeposit * 100; // convert to cents,
              } else {
                updates.refunded = values.securityDeposit * 100; // convert to cents,
              }
              await updateDriver(userData.id, {
                securityDeposit:
                  values.type === "refund"
                    ? (values.securityDeposit + values.amount) * 100
                    : (values.securityDeposit - values.amount) * 100, // convert to cents,
                active: false,
              });
              /*******add to security deposit history */
              await addSecurityDepositHistory(userData.id, updates);
              setAlert(
                <SweetAlert
                  success
                  title="Security Deposit updated successfully"
                  onConfirm={hideAlert}
                />
              );
              setEdit(false);
              setSecurityDeposit(
                values.type === "refund"
                  ? values.securityDeposit + values.amount
                  : values.securityDeposit - values.amount
              );
              fetchSecurityDepositHistory();
            }
          } catch (error) {
            setAlert(
              <SweetAlert
                error
                title={error.response.message || error.message}
                onConfirm={hideAlert}
              />
            );
          }
        }}
        validateOnBlur
        validateOnChange
        validate={async (values) => {
          let errors = {};
          console.log(values);
          if (!values.description) {
            errors = {
              ...errors,
              description: "Please enter a reason for update",
            };
          }
          if (!values.type) {
            errors = {
              ...errors,
              type: "Please select update type.",
            };
          }

          if (!(values.amount > 0)) {
            errors = {
              ...errors,
              amount: "Please enter valid amount.",
            };
          }

          if (
            values.type === "deduct" &&
            values.securityDeposit - values.amount < 0
          ) {
            errors = {
              ...errors,
              amount: "Please enter valid amount.",
            };
          }
          return errors;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {edit && (
              <>
                <Row>
                  <Col className="pr-1" md="4">
                    <Label htmlFor="outlined-age-native-simple">Type</Label>
                    <FormGroup className={classes.loginput}>
                      <Select
                        native
                        fullWidth
                        variant="outlined"
                        value={values.type}
                        onChange={handleChange}
                        label="type"
                        id="type"
                        name="type"
                      >
                        <option aria-label="None" value="" />

                        <option value={"deduct"} key={"deposit"}>
                          {"Deduct"}
                        </option>
                        <option value={"refund"} key={"refunded"}>
                          {"Refund"}
                        </option>
                      </Select>

                      <FormText
                        id="standard-weight-helper-text-type-login"
                        color="danger"
                        tag="span"
                      >
                        {errors.type}
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="4">
                    <FormGroup>
                      <Label>Amount($)</Label>
                      <Input
                        value={values.amount}
                        onChange={handleChange}
                        placeholder="Amount"
                        type="number"
                        name="amount"
                      />
                      <FormText color="danger" tag="span">
                        {errors.amount}
                      </FormText>
                    </FormGroup>
                  </Col>
                  <Col className="pl-1" md="4">
                    <FormGroup>
                      <Label>Security Deposit Left($)</Label>
                      <Input
                        value={
                          values.type === "refund"
                            ? values.securityDeposit + values.amount
                            : values.securityDeposit - values.amount
                        }
                        // onChange={handleChange}
                        disabled={true}
                        placeholder="Security Deposit"
                        type="text"
                        name="securityDeposit"
                      />
                      <FormText color="danger" tag="span">
                        {errors.securityDeposit}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>Reason to update</Label>
                      <Input
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Reason to update"
                        type="text"
                        name="description"
                      />
                      <FormText color="danger" tag="span">
                        {errors.description}
                      </FormText>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}

            {edit ? (
              <Row>
                <Col className={classes.flexStart}>
                  <Button onClick={() => setEdit(!edit)}>Cancel</Button>
                </Col>
                <Col className={classes.flexEnd}>
                  <Button color="success" onClick={() => handleSubmit()}>
                    Update Security Deposit
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className={classes.flex}>
                <Col>
                  <FormGroup>
                    <Label>Security Deposit</Label>
                    <Input
                      value={
                        values.type === "refund"
                          ? values.securityDeposit + values.amount
                          : values.securityDeposit - values.amount
                      }
                      // onChange={handleChange}
                      disabled={true}
                      placeholder="Security Deposit"
                      type="text"
                      name="securityDeposit"
                    />
                    <FormText color="danger" tag="span">
                      {errors.securityDeposit}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col className={classes.flexEnd}>
                  <Button
                    onClick={() => setEdit(true)}
                    style={{ marginTop: 25 }}
                  >
                    <Edit /> Edit
                  </Button>
                </Col>
              </Row>
            )}
          </form>
        )}
      </Formik>

      {securityDepositHistory.length > 0 ? (
        <Table data={securityDepositHistory} columns={columns} />
      ) : (
        <Typography variant="body2" style={{ marginLeft: 5 }}>
          No history found
        </Typography>
      )}
      {alert}
    </div>
  );
};

export default Payment;

/* eslint-disable react/prop-types */
import { Button, makeStyles, Paper } from "@material-ui/core";
import Img from "@material-ui/icons/Image";
import React, { useEffect } from "react";
import { Image } from "../types/model";

const useStyles = makeStyles(() => ({
  root: {
    flex: 550,
  },
  media: {
    height: 140,
    marginTop: 4,
    overflow: "hidden",
    border: 0,
    justifyContent: "center",
    display: "flex",
  },
  image: {
    height: 140,
    objectFit: "contain",
  },
  images: {
    margin: "20px 0",
  },
}));

const ImageUpload: React.FC<{
  onChange: (image: Image) => void;
  image: string | ArrayBuffer | undefined;
  id?: string;
  name?: string;
}> = ({ onChange, image, id, name }) => {
  const [selectedFiles, setSelectedFiles] = React.useState<any>();
  const classes = useStyles();

  const currentFile =
    selectedFiles &&
    selectedFiles?.length &&
    selectedFiles?.length > 0 &&
    selectedFiles[0];

  const imgRef = React.useRef<any>(null);

  useEffect(() => {
    if (image && imgRef.current) {
      imgRef.current.src = image;
    }
  }, [image, imgRef]);

  React.useEffect(() => {
    if (currentFile) {
      const reader = new FileReader();

      imgRef.current.title = currentFile.name;
      imgRef.current.alt = currentFile.name;

      reader.onload = function (event: any) {
        imgRef.current.src = event.target.result;
        onChange({
          data: event.target.result,
          name: currentFile.name,
          type: currentFile["type"],
        });
      };
      reader.readAsDataURL(currentFile);
    }
  }, [currentFile]);

  return (
    <div className={classes.images} key={id}>
      <label htmlFor={id}>
        <input
          type="file"
          accept="image/;capture=camera,image/x-png,image/jpeg,image/jpg"
          id={id}
          name={id}
          style={{ display: "none" }}
          onChange={(event) => {
            const imageFile = event.target.files;
            setSelectedFiles(imageFile);
          }}
        ></input>
        <Button
          className="btn-choose"
          variant="outlined"
          component="span"
          startIcon={<Img />}
          fullWidth
          size="large"
        >
          {name}
        </Button>
      </label>
      {(currentFile || image) && (
        <Paper variant="outlined" className={classes.media}>
          <img ref={imgRef} alt={name} className={classes.image} />
        </Paper>
      )}
    </div>
  );
};

export default ImageUpload;

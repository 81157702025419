import { Driver, SecurityDepositHistory } from "../../types/model";
import { driverCollection, FirebaseCollections } from "../Firebase";

export const findDriverByEmail = (email: string): Promise<Driver | null> => {
  return driverCollection
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Driver> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Driver;
        drivers.push(driver);
      });
      return drivers.length ? drivers[0] : null;
    });
};

export const getDriverById = (driverId: string): Promise<Driver | null> => {
  return driverCollection
    .doc(driverId)
    .get()
    .then((producer) => {
      return { ...producer.data(), id: driverId } as Driver;
    });
};

export const fetchDriver = (docRef: string): Promise<Driver> => {
  return driverCollection
    .doc(docRef)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: doc.id } as Driver;
      } else {
        throw new Error("No driver found");
      }
    });
};

export const createDriver = async (driver: Driver): Promise<Driver> => {
  await driverCollection.doc(driver.id).set(driver);
  return driver;
};

export const updateDriver = async (
  driverId: string,
  driver: Partial<Driver>
): Promise<void> => {
  await driverCollection.doc(driverId).update(driver);
};


export const addSecurityDepositHistory = async (
  driverId: string,
  securityDeposit: SecurityDepositHistory
): Promise<void> => {
  await driverCollection
    .doc(driverId)
    .collection(FirebaseCollections.securityDepositHistory)
    .add(securityDeposit);
};

export const getSecurityDepositHistory = async (
  driverId: string,
  limit?: number,
  from?: string
): Promise<Array<SecurityDepositHistory>> => {
  const historyQuery = driverCollection
    .doc(driverId)
    .collection(FirebaseCollections.securityDepositHistory)
    .orderBy("date", "desc");
  if (limit && !from) {
    return await historyQuery
      .limit(limit)
      .get()
      .then((querySnapShot) => {
        const allHistory: Array<SecurityDepositHistory> = [];
        querySnapShot.forEach((doc) => {
          const depositHistory = {
            ...doc.data(),
            date: doc.data()?.date?.toDate(),
            id: doc.id,
          } as SecurityDepositHistory;
          allHistory.push(depositHistory);
        });
        return allHistory;
      });
  } else if (limit && from) {
    return await historyQuery
      .startAfter(from)
      .limit(limit)
      .get()
      .then((querySnapShot) => {
        const allHistory: Array<SecurityDepositHistory> = [];
        querySnapShot.forEach((doc) => {
          const depositHistory = {
            ...doc.data(),
            date: doc.data()?.date?.toDate(),
            id: doc.id,
          } as SecurityDepositHistory;
          allHistory.push(depositHistory);
        });
        return allHistory;
      });
  } else {
    return await historyQuery.get().then((querySnapShot) => {
      const allHistory: Array<SecurityDepositHistory> = [];
      querySnapShot.forEach((doc) => {
        const depositHistory = {
          ...doc.data(),
          date: doc.data()?.date?.toDate(),
          id: doc.id,
        } as SecurityDepositHistory;
        allHistory.push(depositHistory);
      });
      return allHistory;
    });
  }
};


// get all  pending approval drivers
export const fetchPendingApproval = (): Promise<Array<Driver>> => {
  return driverCollection
    .where("disabled","==", false)
    .where("driverWaitingForApproval", "==", true)
    .where("registrationComplete", "==", true)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Driver> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Driver;
        drivers.push(driver);
      });
      return drivers;
    });
};

// get all incomplete registration drivers
export const fetchIncompleteRegistration = (): Promise<Array<Driver>> => {
  return driverCollection
    .where("disabled","==", false)
    .where("registrationComplete", "==", false)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Driver> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Driver;
        drivers.push(driver);
      });
      return drivers;
    });
};


// get all inactive drivers
export const fetchInactive = (): Promise<Array<Driver>> => {
  return driverCollection
    .where("disabled","==", false)
    .where("approved", "==", true)
    .where("active","==", false)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Driver> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Driver;
        drivers.push(driver);
      });
      return drivers;
    });
};

// get all active drivers
export const fetchActive = (): Promise<Array<Driver>> => {
  return driverCollection
    .where("disabled","==", false)
    .where("active","==", true)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Driver> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Driver;
        drivers.push(driver);
      });
      return drivers;
    });
};


// get all disabled  drivers
export const fetchDisabled = (): Promise<Array<Driver>> => {
  return driverCollection
    .where("disabled","==",true)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Driver> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Driver;
        drivers.push(driver);
      });
      return drivers;
    });
};





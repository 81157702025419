// import AgencyAnalytics from "views/AllAnalytics/AgencyWorkersAnalytics.jsx";
import React from "react";
import AllDeliveries from "../views/all-deliveries/AllDeliveries";
import DeliveryProfile from "../views/all-deliveries/DeliveryProfile";

// drivers
import AllDrivers from "../views/all-users/AllDrivers";
import DriverProfile from "../views/all-users/AllDrivers/driver-profile";

// producers
import AllProducers from "../views/all-users/AllProducers";
import ProducerProfile from "../views/all-users/AllProducers/producer-profile";

export interface RouteView {
  path: string;
  name: string;
  mini: string;
  component: React.FC;
  layout: string;
  hidden: boolean;
}

export interface IRoute {
  collapse: boolean;
  name: string;
  icon: string;
  state: string;
  views: Array<RouteView>;
}

export type Routes = Array<IRoute>;

export const routes: Routes = [
  {
    collapse: true,
    name: "All Deliveries",
    icon: "nc-icon nc-single-02",
    state: "deliveryCollapse",
    views: [
      {
        path: "/all-deliveries",
        name: "All Deliveries",
        mini: "All",
        component: AllDeliveries,
        layout: "/admin",
        hidden: false,
      },
      {
        path: "/delivery-profile/:id",
        name: "Driver Profile",
        mini: "View",
        component: DeliveryProfile,
        layout: "/admin",
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    name: "All Drivers",
    icon: "nc-icon nc-single-02",
    state: "driversCollapse",
    views: [
      {
        path: "/all-drivers",
        name: "All Drivers",
        mini: "All",
        component: AllDrivers,
        layout: "/admin",
        hidden: false,
      },
      {
        path: "/driver-profile/:id",
        name: "Driver Profile",
        mini: "View",
        component: DriverProfile,
        layout: "/admin",
        hidden: true,
      },
    ],
  },
  {
    collapse: true,
    name: "All Producers",
    icon: "nc-icon nc-single-02",
    state: "producersCollapse",
    views: [
      {
        path: "/all-producers",
        name: "All Producers",
        mini: "All",
        component: AllProducers,
        layout: "/admin",
        hidden: false,
      },
      {
        path: "/producer-profile/:id",
        name: "Producer Profile",
        mini: "View",
        component: ProducerProfile,
        layout: "/admin",
        hidden: true,
      },
    ],
  },
];

export default routes;

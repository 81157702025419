import React, { useState } from "react";
import { useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner as ReactSpinner,
} from "reactstrap";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import Background from "../../assets/img/pk-pro-cover.jpg";
import { useAuth } from "../../context/AdminContext";
import { useHistory } from "react-router-dom";

const Login: React.FC<any> = () => {
  const [Login, setLogin] = useState("");
  const [Password, setPassword] = useState("");
  const [alert, setAlert] = useState<React.ReactElement | null>(null);
  const [redirect, setRedirect] = useState(null);
  const [marginTop, setMarginTop] = useState("calc(50vh - 230px)");
  const history = useHistory();
  const auth = useAuth();
  const loading = auth.loading;
  const onSubmit = async () => {
    try {
      await auth.login(Login, Password);
    } catch (e) {
      noSuccessAlert(e.message);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", windowCheck);
    return () => window.removeEventListener("resize", windowCheck);
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push("/admin/all-deliveries");
    }
  }, [auth]);

  const noSuccessAlert = (input: any) => {
    setAlert(
      <ReactBSAlert
        // style={{ display: "block", marginTop: "100px" }}
        title="Login Error"
        onCancel={console.log}
        confirmBtnBsStyle="info"
        onConfirm={() => setAlert(null)}
      >
        {input}
      </ReactBSAlert>
    );
  };

  //What : Changes the marginTop property for the Login Component to ensure a dynamically centered component
  //Input : No input required.
  //Output : Changes marginTop property in state

  const windowCheck = () => {
    if (window.innerHeight > 550) {
      setMarginTop("calc(50vh - 230px)");
    } else {
      setMarginTop("50px");
    }
  };
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="wrapper wrapper-full-page">
      <div className="full-page section-image">
        <div className="login-page">
          {alert}

          <div
            className="full-page-background"
            style={{
              backgroundColor: "rgb(221,221,221)",
              backgroundImage: `url(${Background})`,
              zIndex: 2,
            }}
          />

          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="3"></Col>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form
                  style={{
                    marginTop: marginTop,
                    fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                  }}
                  action=""
                  className="form"
                  method=""
                >
                  <div style={{ position: "fixed", top: "50%", left: "50%" }}>
                    {loading && (
                      <ReactSpinner
                        className="text-center"
                        type="border"
                        color="secondary"
                        size="6"
                      />
                    )}
                  </div>
                  <Card className="card-login">
                    <CardHeader style={{ height: "80px", paddingTop: "10px" }}>
                      <CardHeader>
                        <h3 className="header text-center">LOGIN</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          onChange={(e) => setLogin(e.target.value)}
                          value={Login}
                          style={{
                            fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                          }}
                          placeholder="Email"
                          type="email"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          value={Password}
                          style={{
                            fontFamily: "Trebuchet MS, Helvetica, sans-serif",
                          }}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                      <br />
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        className="btn-round mb-3"
                        // color="warning"
                        // href="#pablo"
                        onClick={onSubmit}
                        // style={{backgroundColor : '#004A8C'}}
                        style={{
                          backgroundColor: "rgb(30,30,30)",
                          width: "100%",
                        }}
                        disabled={!!loading}
                      >
                        {loading ? <ReactSpinner size={10} /> : "Login"}
                      </Button>
                      <Button
                        block
                        className="btn-round mb-3"
                        // color="warning"
                        // href="#pablo"
                        onClick={()=>history.push("/forgot-password")}
                        // style={{backgroundColor : '#004A8C'}}
                        style={{
                          backgroundColor: "rgb(30,30,30)",
                          width: "100%",
                        }}
                        disabled={!!loading}
                      >
                        {loading ? <ReactSpinner size={10} /> : "Forgot Password"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
              <Col className="ml-auto mr-auto" lg="4" md="3"></Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import { useAuth } from "../context/AdminContext";
import routes, { IRoute, Routes, RouteView } from "./routes";
import { useHistory } from "react-router-dom";

let ps: any;

function Admin(props: { history: any; location: any }) {
  const auth = useAuth();
  const [backgroundColor, setBackGroundColor] = useState("black");
  const [activeColor, setActiveColor] = useState("info");
  const [sidebarMini, setSidebarMini] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!auth.user && !auth.loading) {
      history.push("/login");
    }
  }, [auth]);

  const getRoutes = (routes: Routes | Array<RouteView>): React.ReactNode => {
    return (
      <>
        {routes.map((route, key) => {
          const _route: IRoute = route as IRoute;
          const _views: RouteView = route as RouteView;

          if (_route.collapse) {
            return getRoutes(_route.views);
          }

          return (
            <Route
              path={_views.layout + _views.path}
              component={_views.component}
              key={key}
            />
          );
        })}
      </>
    );
  };

  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
        // role={role}
        onLogout={() => {
          auth.logout();
        }}
      />
      <div className="main-panel">
        <AdminNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routes)}</Switch>
        {props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
          <Footer fluid />
        )}
      </div>
    </div>
  );
}

export default Admin;

import { Driver, Producer, ProducerData } from "../../types/model";
import { producerCollection } from "../Firebase";

/************CREATE ORDER *********/
export const findProducerByEmail = (
  email: string
): Promise<Producer | null> => {
  return producerCollection
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      const producers: Array<Producer> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const producer = {
          ...doc.data(),
          id: doc.id,
        } as Producer;
        producers.push(producer);
      });
      return producers.length ? producers[0] : null;
    });
};

export const fetchProducer = (docRef: string): Promise<Producer> => {
  return producerCollection
    .doc(docRef)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: doc.id } as Producer;
      } else {
        throw new Error("No driver found");
      }
    });
};

export const createProducer = async (producer: Producer): Promise<Producer> => {
  await producerCollection.doc(producer.id).set(producer);
  return producer;
};

export const updateProducer = async (
  producer: Partial<Producer>,
  producerId?: string
): Promise<void> => {
  await producerCollection.doc(producerId || producer.id).update(producer);
};


/************Fetch All Producers *********/
export const fetchAllProducers = (): Promise<Array<ProducerData>> => {
  return producerCollection
    .where("disabled","==", false)
    .where("active", "==", true)
    .get()
    .then((querySnapshot) => {
      const producers: Array<ProducerData> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        const producer = {
          ...doc.data(),
          id: doc.id,
          lastUserActivity: doc.data().lastUserActivity ? doc.data().lastUserActivity.toDate() : null,
        } as ProducerData;
        producers.push(producer);
      });
      return producers;
    });
};

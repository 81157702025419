import { Admin } from "../../types/model";
import { adminCollection, auth } from "../Firebase";

export const findDriverByEmail = (email: string): Promise<Admin | null> => {
  return adminCollection
    .where("email", "==", email)
    .get()
    .then((querySnapshot) => {
      const drivers: Array<Admin> = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const driver = {
          ...doc.data(),
          id: doc.id,
        } as Admin;
        drivers.push(driver);
      });
      return drivers.length ? drivers[0] : null;
    });
};

export const getAdminById = (docRef: string): Promise<Admin> => {
  return adminCollection
    .doc(docRef)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: doc.id } as Admin;
      } else {
        throw new Error("No such admin found..");
      }
    });
};

export const sendPasswordResetEmail = async(email: string): Promise<void>=>{
await auth.sendPasswordResetEmail(email)
}

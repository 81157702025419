// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import { Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Profile from "./profile";
import { ProducerData } from "../../../../types/model";
import { fetchProducer } from "../../../../Firebase/dao/producerDao";
import { deActivateUser } from "../../../../Firebase/dao/cloudFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
import Payment from "./payment";
import { useHistory } from "react-router-dom";

const ProducerProfile: React.FC = ({ ...rest }) => {
  let history = useHistory();
  const [pageTabs, setPageTabs] = useState("profile");
  const [userData, setUserData] = useState<ProducerData>();
  const [openModal, setOpenModal] = useState({
    deactive: false,
  });
  const [alert, setAlert] = useState<any>(null);

  useEffect(() => {
    const selectedUrl = window.location.pathname.split("/");
    const userId = selectedUrl[3];
    // console.log("userId", userId)
    if (userId) {
      fetchUserData(userId);
    }
  }, []);

  // fetch producer data from firebase
  const fetchUserData = async (id: string) => {
    try {
      const data = await fetchProducer(id);
      // console.log(data)
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // Opens modal to deactivate user account
  const handleOpenDeactivateModal = () => {
    setOpenModal({ deactive: true });
  };

  const handleCloseDeactivateModal = () => {
    setOpenModal({ deactive: false });
  };

  ////////////////////////////////////////////////////
  // DEACTIVATE USER
  // Popup for DEACTIVATING user from database
  ////////////////////////////////////////////////////
  const deactivateModal = () => {
    return (
      <Modal
        open={openModal.deactive}
        onClose={handleCloseDeactivateModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormGroup
          style={{ width: 350, backgroundColor: "#fff", borderRadius: 10 }}
        >
          <div style={{ padding: 15 }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <h5>Are you sure want to deactivate producer ?</h5>
            </div>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
              <Button onClick={() => handleCloseDeactivateModal()}>
                Cancel
              </Button>
              <Button color="danger" onClick={() => handleDeactivate()}>
                Confirm
              </Button>
            </div>
          </div>
        </FormGroup>
      </Modal>
    );
  };

  const handleDeactivate = async () => {
    try {
      if (userData?.id) {
        await deActivateUser("producer", userData?.id);
        handleCloseDeactivateModal();
        setAlert(
          <SweetAlert
            success
            title="Producer deactived successfully."
            onConfirm={hideAlert}
          />
        );
        history.push("/admin/all-producers");
      }
    } catch (err) {
      handleCloseDeactivateModal();
      setAlert(
        <SweetAlert
          error
          title={err.response.message || err.message}
          onConfirm={hideAlert}
        />
      );
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  //Renders the nav for each of the tabs.
  const renderNavTabs = () => {
    let tabList = [
      { name: "Edit Profile", id: "profile" },
      { name: "Payment and Subscription", id: "payment" },
    ];

    return (
      <>
        {tabList.map((tab) => {
          return (
            <NavItem>
              <NavLink
                data-toggle="tab"
                role="tablist"
                className={pageTabs === tab.id ? "active" : ""}
                onClick={() => {
                  setPageTabs(tab.id);
                }}
              >
                <i className="now-ui-icons ui-2_settings-90" />
                {tab.name}
              </NavLink>
            </NavItem>
          );
        })}
      </>
    );
  };

  return (
    <div className="content">
      {userData && (
        <Row>
          <Col>
            <Card className="no-transition">
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">{userData.firstName} {userData.lastName} Profile</CardTitle>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button color="danger" onClick={handleOpenDeactivateModal}>
                      Deactivate
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                  pills
                  role="tablist"
                >
                  {renderNavTabs()}
                </Nav>
                <TabContent
                  className="tab-space tab-subcategories"
                  activeTab={pageTabs}
                >
                  <TabPane tabId="profile">
                    <Profile data={userData} />
                  </TabPane>
                  <TabPane tabId="payment">
                    <Payment userData={userData} />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          {deactivateModal()}
          {alert}
        </Row>
      )}
    </div>
  );
};

export default ProducerProfile;

/* eslint-disable react/prop-types */

import React, { createContext, useContext, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

type AlertType = "success" | "error" | "warning";
export type IAppContext = {
  createAlert: (title: string, body: string, type: AlertType) => void;
};

export const AppContext = createContext({} as IAppContext);

const AppProvider: React.FC = ({ children }) => {
  const [alert, setAlert] = useState<React.ReactElement | null>(null);

  const createAlert = (
    title: string,
    body?: string,
    type: AlertType = "success"
  ) => {
    setAlert(
      <SweetAlert
        success={type === "success"}
        error={type === "error"}
        warning={type === "warning"}
        title={title}
        onConfirm={() => setAlert(null)}
      >
        {body}
      </SweetAlert>
    );
  };
  return (
    <AppContext.Provider
      value={{
        createAlert,
      }}
    >
      {children}
      {alert}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useAppContext = (): IAppContext => useContext(AppContext);

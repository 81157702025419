import React, { useState, useEffect } from "react";
import {
  DriverData,
  Order,
  OrderStatus,
  PaymentStatusString,
} from "../../../../types/model";
import { getAllDriversOrder } from "../../../../Firebase/dao/orderDao";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Row,
  Button,
} from "reactstrap";
import Table from "../../../../components/ReactTable/Table";
import moment from "moment";
import { Link } from "react-router-dom";
import { Visibility } from "@material-ui/icons";

interface Props {
  userData: DriverData;
}

interface ReactTableOrder extends Order {
  actions: React.ReactElement;
  distanceBike: string;
  distanceMotor: string;
  orderStat: string;
  vehicleType: string;
  updatedAt: string;
  createdAt: string;
  paymentStat: string;
}

function DeliveryHistory({ userData }: Props) {
  const user = userData as DriverData;
  console.log(user);
  const [orders, setOrders] = useState<Array<Order>>([]);
  const [orderLoading, setOrderLoading] = useState(false);

  const columns = [
    {
      Header: "ID",
      accessor: "id", // accessor is the "key" in the data
    },
    {
      Header: "Last Updated",
      accessor: "updatedAt",
    },
    {
      Header: "Distance Bike(appr.)",
      accessor: "distanceBike",
    },
    {
      Header: "Distance Motor(appr)",
      accessor: "distanceMotor",
    },
    {
      Header: "Total Bids",
      accessor: "totalBids",
    },
    {
      Header: "Order Status",
      accessor: "orderStat",
    },
    {
      Header: "Vehicle Type",
      accessor: "vehicleType",
    },
    {
      Header: "Producer Id",
      accessor: "producer",
    },
    {
      Header: "Payment To Driver",
      accessor: "driverPayment",
    },
    {
      Header: "Charge Producer",
      accessor: "producerPayment",
    },
    {
      Header: "Created",
      accessor: "createdAt",
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableFilters: true,
    },
  ];

  const getOrders = async () => {
    setOrderLoading(true);
    try {
      const allOrders = await getAllDriversOrder(user.id as string);
      /***all the bids not related to the drivers are filtered */
      const newOrders = allOrders.map((orders: Order) => {
        orders.bids = orders.bids?.filter((bid) => bid.driverId === user.id);
        return orders;
      });
      const _tableOrders = newOrders.map((order) => {
        return {
          ...order,
          distanceBike: `${(order.approximateDistanceBike / 1000).toFixed(
            2
          )}km`,
          distanceMotor: `${(order.approximateDistanceMotor / 1000).toFixed(
            2
          )}km`,
          orderStat: OrderStatus[order.orderStatus],
          vehicleType:
            order.bidAvailableToBike && order.bidAvailableToMotor
              ? "Bike and Motor"
              : order.bidAvailableToBike
              ? "Bike"
              : "Motor",
          updatedAt: order.updatedDate
            ? moment(order.updatedDate).calendar()
            : "-",
          createdAt: order.createdDate
            ? moment(order.createdDate).calendar()
            : "-",
          paymentProducer: (order?.paymentProducer || 0) / 100,
          paymentDriver: (order?.paymentDriver || 0) / 100,
          actions: RenderActionButtons(order.id!),
          paymentStat: PaymentStatusString[order.paymentStatus],
        } as ReactTableOrder;
      });
      setOrders(_tableOrders);
    } catch (e) {
      console.log(e);
    }
    setOrderLoading(false);
  };

  const RenderActionButtons = (id: string) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/admin/delivery-profile/${id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (user?.vehicleType) getOrders();
  }, [userData]);

  return (
    <div className="content">
      {orders?.length > 0 ? (
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardBody>
                <Table data={orders} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>Looks like no delivery history avaialble for driver.</Col>
        </Row>
      )}
    </div>
  );
}

export default DeliveryHistory;

import moment from "moment";
import React from "react";
import Table from "../../../components/ReactTable/Table";
import {
  OrderStatusHistory,
  OrderStatusString,
  PaymentStatusString,
} from "../../../types/model";

const currentOrderColumns = [
  {
    Header: "Date",
    accessor: "myDate", // accessor is the "key" in the data
  },
  {
    Header: "Order Status",
    accessor: "orderStat",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStat",
  },
  {
    Header: "Description",
    accessor: "remark",
  },
];

interface TableTimeLineData extends OrderStatusHistory {
  myDate: string;
  orderStat: string;
  paymentStat: string;
}
const OrderTimeLine: React.FC<{ timeLine: Array<OrderStatusHistory> }> = ({
  timeLine,
}) => {
  const myTimeLine: Array<TableTimeLineData> = timeLine.map((history) => ({
    ...history,
    myDate: moment(history.date).calendar(),
    orderStat: OrderStatusString[history.orderStatus],
    paymentStat: PaymentStatusString[history.paymentStatus],
  }));
  return (
    <>
      <Table data={myTimeLine} columns={currentOrderColumns} />
    </>
  );
};

export default OrderTimeLine;

export const pastNYears = (n: number): Array<number> => {
  const date = new Date(),
    years = [],
    year = date.getFullYear();

  for (let i = year - n; i < year; i++) {
    years.push(i);
  }
  return years;
};

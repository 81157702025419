import { gql } from "@apollo/client";

export const CreateExternalAccount = gql`
  mutation addNewAccount($input: inputStripeAccount!) {
    addNewAccount(input: $input) {
      id
      requirements {
        currently_due
        eventually_due
      }
    }
  }
`;

export const AddStripeCustomer = gql`
  mutation addNewCustomer($input: inputCustomer!) {
    addNewCustomer(input: $input) {
      id
    }
  }
`;
export const UpdateCustomer = gql`
  mutation updateCustomer($input: inputUpdateCustomer!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

export const AddStripeSubscription = gql`
  mutation addNewSubscription($input: inputSubscription!) {
    addNewSubscription(input: $input) {
      id
      trial_end
    }
  }
`;

export const UpdateSubscription = gql`
  mutation updateSubscription($input: inputUpdateSubscription!) {
    updateSubscription(input: $input) {
      id
    }
  }
`;

export const CreatePayment = gql`
  mutation createPaymentIntent($input: inputPaymentIntent!) {
    createPaymentIntent(input: $input) {
      id
      amount
      currency
      customer
      description
      status
    }
  }
`;
export const CreateCharge = gql`
  mutation createCharge($input: inputCharge!) {
    createCharge(input: $input) {
      id
      amount
      currency
      customer
      description
      status
    }
  }
`;

export const CancelCharge = gql`
  mutation cancelCharge($id: String!) {
    cancelCharge(id: $id) {
      id
    }
  }
`;

export const ConfirmCharge = gql`
  mutation confirmCharge($input: inputCaptureFunds) {
    captureFunds(input: $input) {
      id
    }
  }
`;

export const AttachPaymentToCustomer = gql`
  mutation attachPaymentToCustomer($input: inputAttachPayment) {
    attachPaymentToCustomer(input: $input) {
      id
    }
  }
`;


export type VehicleType = "Bike" | "Motor";
export type PackageType =
  | "Envelope"
  | "Box - Small"
  | "Box-Medium"
  | "Box-Large";
// export type OrderType = "Rush" | "Standard" | "End Of Day" | "Food";
export enum PaymentStatus {
  orderInProgress = 0 /**when the order is in progress */,
  pending = 1 /**order has completed but user hasn't submitted the review yet */,
  dispute_in_progress = 2 /***dispute in progress */,
  notPaid = 3,
  paid = 4,
  error = 5,
}

export const PaymentStatusString = [
  "Order is in Progress",
  "Pending/Waiting for Producer Review.",
  "Order is in dispute.",
  "Not Paid.",
  "Paid.",
  "Error in payment transaction",
];
export enum OrderStatus {
  open = 0,
  driverSelected = 1,
  confirmedByDriver = 2,
  pickedUp = 3,
  delivered = 4,
  deleted = 5,
}

export const OrderStatusString = [
  "Order is open for bids",
  "Driver selected",
  "Driver confirmed the bid",
  "Driver picked up the order",
  "Order delivered",
];

export enum OrderTypeEnum {
  Rush = "Rush",
  Standard = "Standard",
  EOD = "End of Day",
  Food = "Food",
}

export enum PackageWeight {
  "lessThan10" = "0-10kg",
  "over10" = ">10kg up to 23kg",
}

export enum ImageType {
  governmentId = "governmentId",
  driversLicense = "driversLicense",
  insurancePermit = "insurancePermit",
}

export interface Auth {
  email: string;
  password?: string;
}

export interface Review {
  review: string;
  rating: number;
}

export interface Image {
  name?: string;
  data: string;
  type?: string;
}

export interface DriverDocument {
  passport?: string;
  birthCertificate?: string;
  citizenShipCard?: string;
  prCard?: string;
  sin?: string;
  workPermit?: string;
}

export interface DriverRate {
  regular: number;
  rush: number;
  endOfDay: number;
  food: number;
}

export type UserType = "driver" | "producer";
export type FuelType = "Diesel" | "EV" | "Gasoline";

export interface VehicleDetails {
  year?: number;
  make?: string;
  model?: string;
  vin?: string;
  color?: string;
  licensePlate?: string;
  fuelType?: FuelType;
  /****locally these will have image type and in server these will be stored as url string*/
  driversLicense?: string | Image;
  insurancePermit?: string | Image;
  governmentId?: string | Image;
}

export interface CardDetails {
  id?: string;
  name?: string;
  cardNumber: string;
  expiryMonth: number;
  expiryYear: number;
}

export interface Admin extends Auth {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface Driver extends Auth {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  vehicleType?: VehicleType;
  vehicleDetails?: VehicleDetails;
  cardDetails?: CardDetails;
  // driverDocument: DriverDocument;
  ratePerKm?: DriverRate;
  approved?: boolean;
  driverWaitingForApproval?: boolean;
  /***
   * Active or inactive shows if the account is active or not.
   * Account can be inactive if the users payment didn't work or user is approved but haven't paid the deposit
   * it can be other reasons as well
   * */
  active: boolean;
  reviews?: Array<Review>;
  averageRating?: number;
  totalReviews: number;
  securityDeposit?: number;
  userType: UserType;
  createdDate?: Date;
  updatedDate?: Date;

  //data ids from stripe
  stripeCustomer?: string;
  stripeAccount?: string;

  //notifications
  notifications?: Array<Notification>; // notification will be joined after user data is fetched  so this notifications isn't added in Driver collection inherently
  // to know userActiveStatus
  lastUserActivity?: Date;
  disabled?: boolean; 
}

export interface DriverData extends Driver {
  actions?: any;
}

/****security deposit deduction history can be sub-document of driver collection*/

// type SecurityDepositType = "debit" | "credit";
export interface SecurityDepositHistory {
  id?: string;
  deposit?: number;
  deducted?: number;
  refunded?: number;
  description: string;
  date: Date;
  paymentIntent?: string;
}

/****trip can be sub document on driver collection */
export interface Trip {
  start: Date;
  end: Date;
  driver: string | Driver;
  orders: Array<string | Order>;
}

export interface Producer extends Auth {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  imagePath: string;
  stripeSubscription?: string;
  userType: UserType;
  addressDetails?: Address;
  active: boolean;
  cardDetails?: CardDetails;
  stripeCustomer?: string;

  notifications?: Array<Notification>; // notification will be joined after user data is fetched  so this notifications isn't added in Driver collection inherently
  lastUserActivity?: Date;
  disabled?: boolean; 
}

export interface ProducerData extends Producer {
  actions?: any;
  userActive?: string;
}

// export interface Package {
//   deliverTo: string;
// }
export interface Address {
  address1: string;
  address2?: string;
  postalCode: string;
  city: string;
  province: string;
  country?: string;
}

export interface Package {
  type: PackageType;
  weight: PackageWeight;
  number: number;
}
export interface EachAddress {
  address: Address;
  package: Package;
}

export interface Order {
  id?: string;
  deliverTo: Array<EachAddress>; //can have multiple delivery location
  additionalInfo?: string;
  bidAvailableToMotor: boolean; //based on delivery address distance we might limit who the bid is available to.
  bidAvailableToBike: boolean;
  deliveryType: OrderTypeEnum; //if more than one address is selected disable orderType
  producer: string; //producer
  driver?: string; //Driver after being finalized the bid
  review?: Review;
  paymentStatus: PaymentStatus;
  orderStatus: OrderStatus;
  pickedUpTime?: Date;
  pickupLocation: Address;
  deliveredTime?: Date;
  // bids?: Array<Bid>;
  totalBids: number;
  selectedBid?: Bid;
  // orderType: string;
  createdDate?: Date;
  updatedDate?: Date;
  approximateDistanceMotor: number;
  approximateDistanceBike: number;
  actualDistanceTravelled?: number;
  paymentProducer?: number;
  paymentDriver?: number;
  paymentCard?: string /****stripe card id */;
  bids?: Array<Bid>;
  disputeReason?: string;
  paymentId?: string;
  version: number;
}
/***Bid and order status can be sub documents in order collection in firebase*/
/** Bid can be sub documents or driver's collection and order collection. In order collection we also keep the record of bid id from driver's collection. */
export interface Bid {
  bidId?: string;
  orderId: string;
  price: number;
  driverName: string;
  driverId: string;
  driverBidId?: string /*** only stored in sub document or order collection */;
  vehicleType: VehicleType;
  driver?: Driver;
  paymentIntent?: string;
  dateCreated?: Date;
  dateDriverAccepted?: Date;
  driverPhone: string;
}

export interface OrderStatusHistory {
  orderId: string | Order;
  paymentStatus: PaymentStatus;
  orderStatus: OrderStatus;
  date: Date;
  remark: string;
}

/**********Notifications can be sub documents of driver/producer collections *****/
export interface Notification {
  id?: string;
  path?: string /****a url that will be redirected to after clicking the notification */;
  createdAt: Date;
  message: string;
  seen: boolean; // seen or unseen notification
}

/*** */
export interface PaymentHistory {
  id?: string;
  deposit?: number;
  deducted?: number;
  refunded?: number;
  paymentIntent: string;
  description: string;
  createdAt: Date;
  orderId: string;
}

import { PaymentHistory } from "../../types/model";
import {
  driverCollection,
  FirebaseCollections,
  producerCollection,
} from "../Firebase";

/**
 * add payment history
 * @param collectionType
 * @param docId
 * @param paymentHistory
 */
export const addPaymentHistory = async (
  collectionType: FirebaseCollections.driver | FirebaseCollections.producer,
  docId: string,
  paymentHistory: PaymentHistory
): Promise<void> => {
  const collection =
    collectionType === FirebaseCollections.driver
      ? driverCollection
      : producerCollection;

  await collection
    .doc(docId)
    .collection(FirebaseCollections.paymentHistory)
    .add(paymentHistory);

  // await orderCollection.doc(orderId).collection("history").add(history);
};

/**
 *
 * @param collectionType
 * @param docId
 * @param from
 * @param limit
 * @returns
 */
export const fetchPaymentHistory = async (
  collectionType: FirebaseCollections.driver | FirebaseCollections.producer,
  docId: string,
  from?: string,
  limit = 5
): Promise<Array<PaymentHistory>> => {
  const collection =
    collectionType === FirebaseCollections.driver
      ? driverCollection
      : producerCollection;

  const paymentHistoryQuery = collection
    .doc(docId)
    .collection(FirebaseCollections.paymentHistory)
    // .startAfter()
    .orderBy("createdAt", "desc");

  if (limit && !from) {
    return await paymentHistoryQuery
      .limit(limit)
      .get()
      .then((snapShot) => {
        const docs: Array<any> = [];
        snapShot.forEach((doc) =>
          docs.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.toDate(),
          })
        );
        return docs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  } else if (limit && from) {
    return await paymentHistoryQuery
      .startAfter(from)
      .limit(limit)
      .get()
      .then((snapShot) => {
        const docs: Array<any> = [];
        snapShot.forEach((doc) =>
          docs.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.toDate(),
          })
        );
        return docs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  } else {
    return await paymentHistoryQuery
      .get()
      .then((snapShot) => {
        const docs: Array<any> = [];
        snapShot.forEach((doc) =>
          docs.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.toDate(),
          })
        );
        return docs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }
};

export const updatePaymentHistory = async (
  collectionType: FirebaseCollections.driver | FirebaseCollections.producer,
  docId: string,
  paymentHistoryId: string,
  updates: Partial<PaymentHistory>
): Promise<void> => {
  const collection =
    collectionType === FirebaseCollections.driver
      ? driverCollection
      : producerCollection;
  await collection
    .doc(docId)
    .collection(FirebaseCollections.paymentHistory)
    .doc(paymentHistoryId)
    .update(updates);
};

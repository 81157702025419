import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Row,
  Button,
} from "reactstrap";
import { CollectionsOutlined, Visibility } from "@material-ui/icons";
import Table from "../../../components/ReactTable/Table";
import { ProducerData } from "../../../types/model";
import { Link } from "react-router-dom";
import { fetchAllProducers } from "../../../Firebase/dao/producerDao";
import moment from "moment";

const AllProducers = () => {
  const [data, setData] = useState<Array<ProducerData>>([]);
  const columns = React.useMemo(
    () => [
      {
        Header: "FirstName",
        accessor: "firstName", // accessor is the "key" in the data
      },
      {
        Header: "LastName",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Active",
        accessor: "userActive",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  useEffect(() => {
    fetchProducerList();
  }, []);

  const fetchProducerList = async () => {
    try {
      const tempData = await fetchAllProducers();
       const data = tempData.map((d) => {
        d?.lastUserActivity &&
          moment(new Date(d?.lastUserActivity)).isAfter(
            moment().subtract(15, "days")
          );
        return {
          ...d,
          actions: RenderActionButtons(d.id as string),
          userActive: d?.lastUserActivity
            ? moment(new Date(d?.lastUserActivity)).isAfter(
                moment().subtract(15, "days")
              )
              ? "true"
              : "false"
            : "false",
        };
      });
      console.log("producers data", data);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const RenderActionButtons = (id: string) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/admin/producer-profile/${id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">All Producers</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table data={data} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AllProducers;

import { Notification } from "../../types/model";
import {
  driverCollection,
  FirebaseCollections,
  producerCollection,
} from "../Firebase";

/**
 * Add order status history
 * @param orderId
 * @param history
 */
export const addNotification = async (
  collectionType: FirebaseCollections.driver | FirebaseCollections.producer,
  docId: string,
  notification: Notification
): Promise<void> => {
  const collection =
    collectionType === FirebaseCollections.driver
      ? driverCollection
      : producerCollection;

  await collection
    .doc(docId)
    .collection(FirebaseCollections.notification)
    .add(notification);

  // await orderCollection.doc(orderId).collection("history").add(history);
};

export const fetchNotifications = async (
  collectionType: FirebaseCollections.driver | FirebaseCollections.producer,
  docId: string,
  from?: string,
  limit = 5
): Promise<Array<Notification>> => {
  const collection =
    collectionType === FirebaseCollections.driver
      ? driverCollection
      : producerCollection;

  const notificationsQuery = collection
    .doc(docId)
    .collection(FirebaseCollections.notification)
    // .startAfter()
    .orderBy("createdAt", "desc");

  if (limit && !from) {
    return await notificationsQuery
      .limit(limit)
      .get()
      .then((snapShot) => {
        const docs: Array<any> = [];
        snapShot.forEach((doc) =>
          docs.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.toDate(),
          })
        );
        return docs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  } else if (limit && from) {
    return await notificationsQuery
      .startAfter(from)
      .limit(limit)
      .get()
      .then((snapShot) => {
        const docs: Array<any> = [];
        snapShot.forEach((doc) =>
          docs.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.toDate(),
          })
        );
        return docs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  } else {
    return await notificationsQuery
      .get()
      .then((snapShot) => {
        const docs: Array<any> = [];
        snapShot.forEach((doc) =>
          docs.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.toDate(),
          })
        );
        return docs;
      })
      .catch((e) => {
        console.log(e);
        return [];
      });
  }
};

export const updateNotification = async (
  collectionType: FirebaseCollections.driver | FirebaseCollections.producer,
  docId: string,
  notificationId: string,
  updates: Partial<Notification>
): Promise<void> => {
  const collection =
    collectionType === FirebaseCollections.driver
      ? driverCollection
      : producerCollection;
  await collection
    .doc(docId)
    .collection(FirebaseCollections.notification)
    .doc(notificationId)
    .update(updates);
};

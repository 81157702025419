import { async } from "q";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import { Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
import {
  DriverData,
  Order,
  OrderStatus,
  PaymentStatusString,
} from "../../types/model";
import { fs } from "../../Firebase/Firebase";
import {
  getCompletedOrders,
  getCurrentOrders,
  getOrdersInDispute,
  getUnpaidOrders,
} from "../../Firebase/dao/orderDao";
import Table from "../../components/ReactTable/Table";
import moment from "moment";

enum PageTabs {
  current = "Current Orders",
  past = "Past/Completed Orders",
  disputes = "Ongoing Disputes",
  notPaid = "Not Paid",
}

interface ReactTableOrder extends Order {
  actions: React.ReactElement;
  distanceBike: string;
  distanceMotor: string;
  orderStat: string;
  vehicleType: string;
  updatedAt: string;
  createdAt: string;
  paymentStat: string;
}

const currentOrderColumns = [
  {
    Header: "ID",
    accessor: "id", // accessor is the "key" in the data
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
  },
  {
    Header: "Distance Bike(appr.)",
    accessor: "distanceBike",
  },
  {
    Header: "Distance Motor(appr)",
    accessor: "distanceMotor",
  },
  {
    Header: "Total Bids",
    accessor: "totalBids",
  },
  {
    Header: "Order Status",
    accessor: "orderStat",
  },
  {
    Header: "Vehicle Type",
    accessor: "vehicleType",
  },
  {
    Header: "Producer Id",
    accessor: "producer",
  },
  {
    Header: "Driver Id",
    accessor: "driver",
  },

  {
    Header: "Created",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableFilters: true,
  },
];
const pastOrdersColumns = [
  {
    Header: "ID",
    accessor: "id", // accessor is the "key" in the data
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
  },
  {
    Header: "Distance Bike(appr.)",
    accessor: "distanceBike",
  },
  {
    Header: "Distance Motor(appr)",
    accessor: "distanceMotor",
  },
  {
    Header: "Total Bids",
    accessor: "totalBids",
  },
  {
    Header: "Order Status",
    accessor: "orderStat",
  },
  {
    Header: "Vehicle Type",
    accessor: "vehicleType",
  },
  {
    Header: "Producer Id",
    accessor: "producer",
  },
  {
    Header: "Driver Id",
    accessor: "driver",
  },
  {
    Header: "Payment To Driver",
    accessor: "paymentDriver",
  },
  {
    Header: "Charge Producer",
    accessor: "paymentProducer",
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableFilters: true,
  },
];

const onGoingDisputeColumns = [
  {
    Header: "ID",
    accessor: "id", // accessor is the "key" in the data
  },
  {
    Header: "Last Updated",
    accessor: "updatedAt",
  },
  {
    Header: "Distance Bike(appr.)",
    accessor: "distanceBike",
  },
  {
    Header: "Distance Motor(appr)",
    accessor: "distanceMotor",
  },
  {
    Header: "Total Bids",
    accessor: "totalBids",
  },
  {
    Header: "Order Status",
    accessor: "orderStat",
  },
  {
    Header: "Vehicle Type",
    accessor: "vehicleType",
  },
  {
    Header: "Producer Id",
    accessor: "producer",
  },
  {
    Header: "Driver Id",
    accessor: "driver",
  },
  {
    Header: "Payment To Driver",
    accessor: "driverPayment",
  },
  {
    Header: "Charge Producer",
    accessor: "producerPayment",
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStat",
  },
  {
    Header: "Actions",
    accessor: "actions",
    disableFilters: true,
  },
];

const AllDrivers = () => {
  const [pageTabs, setPageTabs] = useState<PageTabs>(PageTabs.current);

  const [orders, setOrders] = React.useState<Array<ReactTableOrder>>([]);

  const columns = React.useMemo(
    () =>
      pageTabs === PageTabs.current
        ? currentOrderColumns
        : pageTabs === PageTabs.past
        ? pastOrdersColumns
        : onGoingDisputeColumns,
    [pageTabs]
  );

  const getOrders = async (pageTabs: PageTabs) => {
    const orders =
      pageTabs === PageTabs.current
        ? await getCurrentOrders()
        : pageTabs === PageTabs.past
        ? await getCompletedOrders()
        : pageTabs === PageTabs.disputes
        ? await getOrdersInDispute()
        : await getUnpaidOrders();
    const _tableOrders = orders.map((order) => {
      return {
        ...order,
        distanceBike: `${(order.approximateDistanceBike / 1000).toFixed(2)}km`,
        distanceMotor: `${(order.approximateDistanceMotor / 1000).toFixed(
          2
        )}km`,
        orderStat: OrderStatus[order.orderStatus],
        vehicleType:
          order.bidAvailableToBike && order.bidAvailableToMotor
            ? "Bike and Motor"
            : order.bidAvailableToBike
            ? "Bike"
            : "Motor",
        updatedAt: order.updatedDate
          ? moment(order.updatedDate).calendar()
          : "-",
        createdAt: order.createdDate
          ? moment(order.createdDate).calendar()
          : "-",
        paymentProducer: (order?.paymentProducer || 0) / 100,
        paymentDriver: (order?.paymentDriver || 0) / 100,
        actions: RenderActionButtons(order.id!),
        paymentStat: PaymentStatusString[order.paymentStatus],
      } as ReactTableOrder;
    });
    setOrders(_tableOrders);
  };

  useEffect(() => {
    getOrders(pageTabs);
  }, [pageTabs]);

  console.log("orders", orders);

  const RenderActionButtons = (id: string) => {
    return (
      <div className="actions-right">
        <Button
          tag={Link}
          to={`/admin/delivery-profile/${id}`}
          color="info"
          size="md"
          className="btn-link btn-icon"
        >
          <Visibility style={{ marginLeft: -1 }} />
        </Button>
      </div>
    );
  };

  //Renders the nav for each of the tabs. Title and Name are strings
  const MyNavItem = ({ tabTitle }: { tabTitle: PageTabs }) => {
    return (
      <NavItem>
        <NavLink
          data-toggle="tab"
          role="tablist"
          className={pageTabs === tabTitle ? "active" : ""}
          onClick={() => setPageTabs(tabTitle)}
        >
          <i className="now-ui-icons ui-2_settings-90" />
          {tabTitle}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="no-transition">
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">All Deliveries</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav
                  className="nav-pills-primary nav-pills-icons justify-content-center"
                  pills
                  role="tablist"
                >
                  {(Object.keys(PageTabs) as Array<keyof typeof PageTabs>).map(
                    (key) => {
                      return <MyNavItem tabTitle={PageTabs[key]} />;
                    }
                  )}
                </Nav>
                <Table data={orders} columns={columns} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AllDrivers;
